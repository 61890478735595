<template>
    <client-page>

        <template slot="subHead">
            <sub-visual sh="Partners" tabActive="쇼룸·대리점 안내" bg="/images/sub/visual/sv-partners.jpg" />
        </template>

        <page-section class="page-section--first page-section--last">
            <partners-list />
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import PartnersList from "@/components/client/partners/partners-list.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        PartnersList,

        Tit,
        Txt,
    },
}
</script>

<style>

</style>