var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "flat": ""
    }
  }, 'v-card', _vm.$attrs, false), [_c('div', {
    staticClass: "pa-16px pa-md-24px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', [_c('txt', {
    staticClass: "font-weight-medium line-height-1 primary--text",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("0" + _vm._s(_vm.index + 1))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "150",
      "src": _vm.icon,
      "max-width": "36",
      "eager": "",
      "contain": "",
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-16px mt-md-32px"
  }, [_c('txt', {
    staticClass: "txt--dark font-weight-medium break-keep",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(_vm._s(_vm.title))]), _vm.sc ? _c('txt', {
    staticClass: "font-secondary mt-4px mt-md-8px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(_vm._s(_vm.sc))]) : _vm._e()], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }