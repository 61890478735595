var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-list-faq"
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.faqs, function (faq, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_c('div', {
      staticClass: "board-list-faq__header"
    }, [_c('div', {
      staticClass: "board-list-faq__header-mark"
    }, [_c('span', [_vm._v("Q")])]), _c('div', {
      staticClass: "board-list-faq__tit"
    }, [_c('span', [_vm._v(_vm._s(faq.subject))])])])]), _c('v-expansion-panel-content', [_c('div', {
      staticClass: "board-list-faq__content"
    }, [_c('div', {
      staticClass: "board-list-faq__content-mark"
    }, [_c('span', [_vm._v("A")])]), _c('div', {
      staticClass: "board-list-faq__txt"
    }, [_c('span', {
      staticClass: "board-list-faq__html",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })])])])], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }