var render = function render(){
  var _vm$board, _vm$board$image, _vm$board2, _vm$board4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "contentsImmersive"
  }, [_c('div', {
    staticClass: "reference-view__head"
  }, [_c('div', {
    staticClass: "reference-view__head-bg",
    style: `background-image: url(${(_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : (_vm$board$image = _vm$board.image) === null || _vm$board$image === void 0 ? void 0 : _vm$board$image.src})`
  }), _c('v-container', [_c('div', {
    staticClass: "reference-view__head-tit"
  }, [_c('tit', {
    staticClass: "tit--xl white--text"
  }, [_vm._v(_vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.series))])], 1)]), _c('div', {
    staticClass: "scroll-ani"
  }, [_c('span', {
    staticClass: "scroll-ani__inner"
  })])], 1)]), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('tit-wrap-tertiary', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "TxtAdd",
      fn: function () {
        var _vm$board3;
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.introduction)
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" " + _vm._s((_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : _vm$board4.series) + " ")]), _c('div', {
    staticClass: "mt-20px mt-md-40px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-tertiary', {
    attrs: {
      "to": "/product"
    }
  }, [_vm._v("리스트")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-secondary', {
    attrs: {
      "to": "/service/inquiry"
    }
  }, [_vm._v("견적문의")])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--last grey lighten-5"
  }, [_c('div', {
    staticClass: "mb-n60px mb-lg-n100px"
  }, _vm._l(_vm.board.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-60px mb-lg-100px"
    }, [_c('tit-wrap-quarternary', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.subject))]), _c('v-row', {
      staticClass: "row--xl my-n24px my-md-n20px"
    }, _vm._l(item.products, function (product, index) {
      var _product$image;
      return _c('v-col', {
        key: index,
        staticClass: "py-24px py-md-20px",
        attrs: {
          "cols": "12",
          "lg": "6"
        }
      }, [_c('v-row', [_c('v-col', {
        attrs: {
          "cols": "12",
          "sm": "6"
        }
      }, [_c('v-card', {
        attrs: {
          "outlined": "",
          "tile": "",
          "flat": "",
          "color": "reference-view__thumb-card thumb-btn-wrap"
        },
        on: {
          "click": function ($event) {
            return _vm.$refs.product.open(_vm.board.series, item, index);
          }
        }
      }, [_c('div', {
        staticClass: "thumb-btn"
      }, [_c('v-img', {
        staticClass: "w-100",
        attrs: {
          "src": product === null || product === void 0 ? void 0 : (_product$image = product.image) === null || _product$image === void 0 ? void 0 : _product$image.src,
          "aspect-ratio": 1 / 1
        }
      }), _c('u-btn-icon-flat-tile', {
        staticClass: "v-btn--none-active-bg",
        attrs: {
          "color": "primary"
        }
      }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)])], 1), _c('v-col', {
        attrs: {
          "cols": "12",
          "sm": "6"
        }
      }, [_c('v-card', {
        staticClass: "h-100",
        attrs: {
          "outlined": "",
          "tile": "",
          "flat": ""
        }
      }, [_c('div', {
        staticClass: "pa-12px w-100 h-100 white"
      }, [_c('v-row', {
        staticClass: "ma-n1px row-cols-5"
      }, _vm._l(product._colors, function (color, index) {
        var _color$image;
        return _c('v-col', {
          key: index,
          staticClass: "pa-1px"
        }, [_c('v-card', {
          attrs: {
            "outlined": "",
            "tile": "",
            "flat": ""
          }
        }, [_c('v-responsive', {
          staticClass: "w-100 image-card",
          style: `background-image: url(${color === null || color === void 0 ? void 0 : (_color$image = color.image) === null || _color$image === void 0 ? void 0 : _color$image.src})`,
          attrs: {
            "aspect-ratio": 1 / 1,
            "content-class": "d-flex align-center justify-center"
          }
        }, [_c('span', {
          staticClass: "reference-view__color-title"
        }, [_vm._v(_vm._s(color.code))])])], 1)], 1);
      }), 1)], 1)])], 1)], 1), _c('div', {
        staticClass: "pt-16px pt-md-24"
      }, [_c('txt', {
        staticClass: "txt--dark font-weight-medium font-secondary"
      }, [_vm._v(" " + _vm._s(product === null || product === void 0 ? void 0 : product.name) + " ")])], 1), _c('div', {
        staticClass: "pt-16px pt-md-24 mb-n8px mb-md-n12px"
      }, _vm._l(product.items, function (item, index) {
        var _item$price;
        return _c('div', {
          key: index
        }, [_c('v-row', {
          staticClass: "row--xs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt', {
          staticClass: "txt--xs txt--dark line-height--1"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.code))])], 1), _c('v-col', [_c('txt', {
          staticClass: "txt--xs txt--light line-height-1"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.size))])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt', {
          staticClass: "txt--xs primary--text line-height-1 text-right"
        }, [_vm._v("￦ " + _vm._s(item === null || item === void 0 ? void 0 : (_item$price = item.price) === null || _item$price === void 0 ? void 0 : _item$price.format()))])], 1)], 1), _c('v-divider', {
          staticClass: "my-8px my-md-12px grey lighten-3"
        })], 1);
      }), 0)], 1);
    }), 1)], 1);
  }), 0)]), _c('product-popup-view', {
    ref: "product"
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }