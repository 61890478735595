var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "subHead"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "Reference",
      "bg": "/images/sub/visual/sv-reference.jpg"
    }
  })], 1), _c('reference-category', {
    on: {
      "category": _vm.setCategory
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last grey lighten-5"
  }, [_c('reference-list', {
    attrs: {
      "boards": _vm.boards
    }
  }), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.init
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }