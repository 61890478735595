<template>
    <client-page>

        <template slot="subHead">
            <sub-visual sh="About" bg="/images/sub/visual/sv-about.jpg" />
        </template>

        <div v-scroll="onScrollFixed" class="scroll-fixed-tab w-100">
            <scrollactive :highlightFirstItem="true" active-class="v-tab--active" class="scroll-fixed-tab__inner v-tabs">
                <router-link to="#brand_story" class="scrollactive-item v-tab">brand story</router-link>
                <router-link to="#competitivity" class="scrollactive-item v-tab">VIITZ만의 경쟁력</router-link>
                <router-link to="#company" class="scrollactive-item v-tab">기업 소개</router-link>
            </scrollactive>
        </div>

        <page-section id="brand_story" class="page-section--first pb-0">
            <template slot="pageSectionImmersive">
                <page-section class="pt-0">
                    <tit-wrap-secondary class="pb-0">
                        고객을 먼저 생각하는 공간 <br>
                        가치 있는 오피스 가구의 시작 VIITZ
                    </tit-wrap-secondary>
                </page-section>
                <page-section style="background-image: url(/images/sub/about/about-bg.jpg)">
                    <v-img data-aos="fade-up" src="/images/sub/about/about-img.png" max-width="660" :aspect-ratio="660 / 318" contain class="w-100 mx-auto" />
                </page-section>
                <page-section>
                    <div>
                        <v-row align="center">
                            <v-col data-aos="fade-right" cols="12" md="6">
                                <v-img src="/images/sub/about/about-img2.jpg" max-width="588" :aspect-ratio="588 / 392" class="w-100" />
                            </v-col>
                            <v-col data-aos="fade-left" cols="12" md="6">
                                <div class="pl-md-20px pl-lg-40px">
                                    <txt class="txt--dark line-height-15 break-keep font-secondary">
                                        VIITZ는 실행 가능하고, 완벽한 공간 창조를 제공합니다. <br><br>
                                        부엌, 인테리어 가구 부문의 역사와 노하우를 바탕으로 탄생한 오피스 브랜드 VIITZ는 고객의 맞춤형 공간을 만들기 위해 끊임없는 연구와 새로운 도전을   통해 품질과 디자인, 서비스에서 고객 감동을 실현합니다.
                                    </txt>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mt-30px mt-md-48px">
                        <v-row align="center">
                            <v-col data-aos="fade-left" cols="12" md="6" order-md="2">
                                <v-img src="/images/sub/about/about-img3.jpg" max-width="588" :aspect-ratio="588 / 392" class="w-100" />
                            </v-col>
                            <v-col data-aos="fade-right" cols="12" md="6" order-md="1">
                                <div class="pr-md-20px pr-lg-40px">
                                    <txt class="txt--dark line-height-15 break-keep font-secondary">
                                        VIITZ는 Office, Interior, Medical, Education, Public, Dormitory 위 부문의 전문 브랜드 입니다. <br><br>
                                        40여년의 전통과 경험을 바탕으로 국내 사무용가구의 새로운 역사를 시작한 기업인 VIITZ는 고객 여러분께 만족을 드릴 수 있는 사무공간 창출을 위해 안전하고 트랜드한 제품 생산, 개발에 최선을 다하고 있습니다. <br><br>
                                        VIITZ는 환경친화적인 공간, IT 발전에 발맞춰 미래로 도약하는 공간, 고객의  맞춤형 공간을 만들기 위해 끊임없이 노력하고 있습니다. 다양한 공간의 특성을 분석하고 연구하여 새로운 시도를 통해 최적의 공간, 효율적인 공간, 아름다운 공간을 이루어 내고자 합니다.
                                    </txt>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </page-section>
                <page-section class="grey lighten-5">
                    <tit-wrap-tertiary>
                        연혁
                    </tit-wrap-tertiary>   
                    <about-history />      
                </page-section>
            </template>
        </page-section>

        <page-section id="competitivity" class="page-section--first page-section--last">
            <template slot="pageSectionImmersive">
                <page-section class="pt-0">
                    <tit-wrap-secondary>
                        VIITZ만의 경쟁력
                    </tit-wrap-secondary>
                    <tit-wrap-tertiary>
                        제품 생산/물류/시공 One-Stop 서비스
                        <template #TxtAdd>
                            거점별 Order Made 생산, 가장 빠른 물류시공 System
                        </template>
                    </tit-wrap-tertiary>
                    <v-card outlined style="border-bottom-width: 0 !important;">
                        <v-row no-gutters>
                            <v-col cols="12" md="6" lg="7" class="border-b" :class="$vuetify.breakpoint.mdAndUp ? 'border-r' : ''">
                                <div class="h-100 pa-20px pa-md-30px pa-lg-40px d-flex justify-center align-center">
                                    <v-img src="/images/sub/about/about-img4.svg" max-width="584" :aspect-ratio="584 / 500" contain class="w-100" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" lg="5">
                                <u-card-icon v-for="(item, index) in info" :key="item.title" :index="index" :title="item.title" :sc="item.sc" :icon="item.icon" class="border-b" style="min-height: calc(100% / 3)" />
                            </v-col>
                        </v-row>
                    </v-card>
                </page-section>
                <page-section class="pt-0">
                    <tit-wrap class="tit-wrap--sm text-center text-md-left">
                        <tit data-aos="fade-up" class="tit--sm">One-Stop 납품 프로세스</tit>
                    </tit-wrap>
                    <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/about/about-img5.svg" max-width="1200" :aspect-ratio="1200 / 290" contain class="w-100 mx-auto d-none d-md-block" />
                    <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/about/about-img5-mo.svg" max-width="350" :aspect-ratio="350 / 930" contain class="w-100 mx-auto d-md-none" />
                </page-section>
                <page-section class="pt-0">
                    <tit-wrap class="tit-wrap--sm text-center text-md-left">
                        <tit data-aos="fade-up" class="tit--sm">One-Stop 납품을 위한 4가지 관리 체계</tit>
                    </tit-wrap>
                    <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/about/about-img6.svg" max-width="1200" :aspect-ratio="1200 / 500" contain class="w-100 mx-auto d-none d-md-block" />
                    <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/about/about-img6-mo.svg" max-width="350" :aspect-ratio="350 / 660" contain class="w-100 mx-auto d-md-none" />
                </page-section>
                <page-section class="pt-0">
                    <v-divider />
                </page-section>
                <page-section class="pt-0">
                    <tit-wrap-tertiary>
                        품질 경쟁력
                    </tit-wrap-tertiary>
                    <v-card flat color="grey lighten-5">
                        <div class="pa-20px pa-md-20px py-lg-40px px-lg-10px">
                            <v-row no-gutters class="mb-n16px mb-md-0">
                                <v-col v-for="item in competitivity" :key="item.title" cols="12" md="4" class="mb-16px mb-md-0">
                                    <div class="px-md-10px px-lg-50px">
                                        <v-row data-aos="fade-up" align="center" class="row--xxs">
                                            <v-col cols="auto">
                                                <v-img src="/images/icon/icon-check.svg" eager max-width="16" :aspect-ratio="1 / 1" />
                                            </v-col>
                                            <v-col>
                                                <txt class="txt--xs primary--text">{{item.title}}</txt>
                                            </v-col>
                                        </v-row>
                                        <txt data-aos="fade-up" data-aos-delay="100" class="txt--dark font-weight-medium break-keep mt-4px mt-md-8px">
                                            {{item.info}}
                                        </txt>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                    <div class="mt-30px mt-md-60px">
                        <v-row>
                            <v-col v-for="detail in cert" :key="detail" cols="4" md="2">
                                <v-img data-aos="fade-up" :src="detail" max-width="180" :aspect-ratio="180 / 248" class="w-100 mx-auto" />
                            </v-col>
                        </v-row>
                    </div>
                </page-section>
                <page-section class="pt-0">
                    <v-divider />
                </page-section>
                <page-section class="pt-0 pb-0">
                    <tit-wrap-tertiary>
                        전문 서비스
                    </tit-wrap-tertiary>
                    <v-card tile flat color="grey lighten-5">
                        <div class="pa-20px pa-md-30px px-lg-60px py-lg-40px">
                            <v-row class="mx-lg-n20px mx-xl-n50px">
                                <v-col cols="12" md="auto" class="px-lg-20px px-xl-50px">
                                    <txt data-aos="fade-up" class="txt--xs line-height-1 mb-4px mb-md-8px">01</txt>
                                    <txt data-aos="fade-up" data-aos-delay="100" class="txt--dark font-weight-medium">
                                        자체 개발 ERP system / 비츠 캐드 보유
                                    </txt>
                                </v-col>
                                <v-col cols="12" md="" class="px-lg-20px px-xl-50px">
                                    <txt data-aos="fade-up" class="txt--xs line-height-1 mb-4px mb-md-8px">02</txt>
                                    <txt data-aos="fade-up" data-aos-delay="100" class="txt--dark font-weight-medium font-secondary">
                                        공간 컨설팅 (오피스 + INT) / 전문 대리점 • 인력 보유
                                    </txt>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                    <div class="mt-30px mt-md-60px">
                        <v-card outlined flat>
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/about/about-img8.jpg" max-width="906" :aspect-ratio="906 / 598" contain class="w-100 mx-auto" />
                            </div>
                        </v-card>
                    </div>
                </page-section>                
            </template>
        </page-section>

        <page-section id="company" class="page-section--first page-section--last" style="background-image: url(/images/sub/about/about-bg2.jpg)">
            <v-row>
                <v-col cols="12" md="6">
                    <tit-wrap>
                        <tit data-aos="fade-up" class="tit--lg white--text">기업 소개</tit>
                    </tit-wrap>
                    <div data-aos="fade-up" data-aos-delay="100">
                        <btn-primary2 href="https://www.effex.co.kr/" target="_blank">이펙스 기업 소개</btn-primary2>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <v-divider style="border-color: rgba(255, 255, 255, 0.3) !important;" />
                    <v-card v-for="item in company" :key="item.title" data-aos="fade-down" :data-aos-delay="item.aosDelay" :href="item.link" target="_blank" outlined flat style="border-color: rgba(255, 255, 255, 0.3) !important; border-top-width: 0 !important;">
                        <div class="pa-20px pa-md-30px pa-lg-40">
                            <v-row align="center">
                                <v-col cols="12" sm="auto">
                                    <v-img :src="item.icon" max-width="36" :aspect-ratio="1 / 1" contain />
                                </v-col>
                                <v-col>
                                    <txt class="txt--xs white--text line-height-1 mb-4px mb-md-8px">{{item.title}}</txt>
                                    <txt class="font-secondary white--text font-weight-medium">{{item.info}}</txt>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import AboutHistory from "@/components/client/about/about-history.vue";
import UCardIcon from "@/components/publish/styles/cards/u-card-icon.vue";

import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import TitWrapTertiary from "@/components/publish/parents/typography/tit-wrap-tertiary.vue";
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import BtnPrimary2 from "@/components/publish/parents/buttons/btn-primary2.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        AboutHistory,
        UCardIcon,

        TitWrapSecondary,
        TitWrapTertiary,
        TitWrap,
        BtnPrimary2,
        Tit,
        Txt,
    },
    data() {
        return {            
            info : [
                { title : "거점별 생산/물류/시공 ", icon : "/images/sub/about/about-icon1-1.svg", sc : "Order Made 제품 거점별 생산/물류/시공", },
                { title : "책임 시공 서비스", icon : "/images/sub/about/about-icon1-2.svg", sc : "납기/품질/CS/AS 책임 시공 관리", },
                { title : "One-Stop 서비스", icon : "/images/sub/about/about-icon1-3.svg", sc : "생산/물류/시공 원스톱 서비스 제공", },
            ],            
            competitivity : [
                { title : "검증", info : "시스템과 제품에 대한 검증 / 국내 검증 기관에서 검증 시행", },
                { title : "표준 시스템", info : "ISO 9001 품질경영 시스템, ISO 14001 환경경영 시스템", },
                { title : "품질 보증", info : "친환경 자재/공정/완제품 시험 및 검사, BIFMA/KS 가구시험 절차 및 기준 준수", },
            ],           
            cert : [
                "/images/sub/about/about-img7-1.jpg",
                "/images/sub/about/about-img7-2.jpg",
                "/images/sub/about/about-img7-3.jpg",
                "/images/sub/about/about-img7-4.jpg",
                "/images/sub/about/about-img7-5.jpg",
                "/images/sub/about/about-img7-6.jpg",
            ],            
            company : [
                {
                    title : "오시는 길 - 서울 사무실",
                    info : "서울특별시 강남구 테헤란로 126 대공빌딩 7층 ㈜이펙스",
                    link : "https://www.google.com/maps/place/%EB%8C%80%EA%B3%B5%EB%B9%8C%EB%94%A9/data=!3m1!4b1!4m6!3m5!1s0x357ca1564e9225a5:0x3230bb27f9659b62!8m2!3d37.4990163!4d127.0323272!16s%2Fg%2F1tdjzjfx?authuser=0&entry=ttu",
                    icon : "/images/icon/icon-point.svg",
                },
                {
                    title : "오시는 길 - 안산 공장",
                    info : "경기도 안산시 단원구 산단로19번길 52 (우)15611",
                    link : "https://www.google.com/maps/place/%EA%B2%BD%EA%B8%B0%EB%8F%84+%EC%95%88%EC%82%B0%EC%8B%9C+%EB%8B%A8%EC%9B%90%EA%B5%AC+%EC%82%B0%EB%8B%A8%EB%A1%9C19%EB%B2%88%EA%B8%B8+52/data=!3m1!4b1!4m6!3m5!1s0x357b71fe8b2ca35b:0xc1a521548694ce2!8m2!3d37.299753!4d126.7794479!16s%2Fg%2F11bzg9yggv?authuser=0&entry=ttu",
                    icon : "/images/icon/icon-point.svg",
                    aosDelay : 50,
                },
                {
                    title : "대표 전화번호",
                    info : "1577-6536",
                    link : "tel:15776536",
                    icon : "/images/icon/icon-tel.svg",
                    aosDelay : 100,
                },
                {
                    title : "이메일",
                    info : "mailto:viitz@effex.co.kr",
                    link : "mailto:viitz@effex.co.kr",
                    icon : "/images/icon/icon-mail.svg",
                    aosDelay : 150,
                },
            ],
        };
    },
    mounted() {
    },
    methods: {
        onScrollFixed: function() {
            let scrollPosition = 0,
                mobileWidth = 1200;

            let tabsWrap = document.querySelector(".scroll-fixed-tab"),
                tabs = document.querySelector(".scroll-fixed-tab .v-tabs"),
                absoluteTop = 0,
                headerHeight = 56;
            if (window.innerWidth > mobileWidth) {
                headerHeight = 100;
            }

            scrollPosition = window.scrollY || window.pageYOffset;
            absoluteTop = window.pageYOffset + tabsWrap.getBoundingClientRect().top - headerHeight;

            if (scrollPosition < absoluteTop) {
                tabs.classList.remove("active");
            } else {
                tabs.classList.add("active");
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.v-tab{
    text-transform: uppercase;
}
</style>
