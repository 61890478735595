<template>
    <div class="history mb-n30px mb-md-n60px pl-22px px-md-0">
        <v-row v-for="(history, index) in history" :key="index" no-gutters class="history__row mx-md-n20px mx-lg-n44px mb-30px mb-md-60px">
            <v-col data-aos="fade-up" cols="12" md="6" class="px-md-20px px-lg-44px mb-12px mb-md-0">
                <tit class="history__tit tit--sm line-height-1">{{history.title}}</tit>
            </v-col>
            <v-col data-aos="fade-up" data-aos-delay="100" class="px-md-20px px-lg-44px mb-n6px mb-md-n12px">
                <v-row v-for="(year, index) in history.year" :key="index" no-gutters class="history__year-row pt-md-2px mb-6px mb-md-12px">
                    <v-col cols="auto">
                        <div class="min-w-40px min-w-md-60px mr-8px mr-md-0 history__year">
                            <txt class="txt--sm txt--dark font-secondary font-weight-medium line-height-15">{{year.title}}</txt>
                        </div>
                    </v-col>
                    <v-col>
                        <v-row v-for="(detail, index) in year.info" :key="index" class="history__info-row row--xxs">
                            <v-col cols="auto">
                                <txt class="txt--sm line-height-15">·</txt>
                            </v-col>
                            <v-col>
                                <txt class="txt--sm line-height-15">{{detail}}</txt>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Tit,
        Txt,
    },
    data() {
        return {            
            history: [
                {
                    title : "2022 ~ 2020",
                    year : [
                        {
                            title : "2022",
                            info : [
                                "(주)한샘이펙스 → (주)이펙스 상호 변경",
                            ],
                        },
                        {
                            title : "2020",
                            info : [
                                "서초전 시장 리뉴얼 오픈",
                            ],
                        },
                    ],
                },
                {
                    title : "2019 ~ 2010",
                    year : [
                        {
                            title : "2019",
                            info : [
                                "이펙스 독립법인, 퍼니스템 인수",
                            ],
                        },
                        {
                            title : "2018",
                            info : [
                                "안산상공회의소 경영대상 수상",
                                "검찰/노동부 선정 대기배출 우수 사업장 선정",
                                "인조대리석 품질분임조 전국대회 은상 수상",
                            ],
                        },
                        {
                            title : "2017",
                            info : [
                                "중역용 가구 “RAPLAS” 우수디자인 선정 (GD마크 획득)",
                                "인조대리석 경기도 표준협회 품질분임조 현장개선부문 우수상 수상",
                                "서울은평성모병원,부천성모병원 수주 / 납품",
                            ],
                        },
                        {
                            title : "2016",
                            info : [
                                "사무용 가구 “FLOT”혁신상품 이노스타 인증 획득",
                                "사무가구 전문 “비츠캐드” 개발",
                            ],
                        },
                        {
                            title : "2014",
                            info : [
                                "인조대리석 특판 최대설비 신규라인 도입 ”인조대리석 자동화 라인구축”",
                            ],
                        },
                        {
                            title : "2013",
                            info : [
                                "사무용가구 “XENSIA”, ”LEABEN” 우수디자인 선정 (GD마크 획득)",
                            ],
                        },
                        {
                            title : "2012",
                            info : [
                                "사무용 가구 “TICEN” 우수디자인 선정 (GD마크 획득)",
                            ],
                        },
                        {
                            title : "2010",
                            info : [
                                "ISO 9001 / ISO 14001 획득",
                            ],
                        },
                    ],
                },
                {
                    title : "2009 ~ 2000",
                    year : [
                        {
                            title : "2009",
                            info : [
                                "사무용 가구 조달청 등록",
                                "인조대리석 중국 / 일본 수출",
                            ],
                        },
                        {
                            title : "2008",
                            info : [
                                "인조대리석 부엌 가구 상판 “한샘스톤” 출시",
                                "사무용 가구 “비츠” 런칭",
                            ],
                        },
                        {
                            title : "2006",
                            info : [
                                "에펙스산업㈜과의 흡수 합병으로 ㈜한샘이펙스 상호 변경 (2006.11)",
                                "인조대리석 부엌 가구 상판 생산 라인 구축",
                            ],
                        },
                    ],
                },
                {
                    title : "1999 ~ 1978",
                    year : [
                        {
                            title : "~1978",
                            info : [
                                "회사설립 (1978.8) ”한샘산업주식회사”",
                            ],
                        },
                    ],
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.history{
    position: relative;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: calc(100% - 20px);
        top: 50%;
        left: 3px;
        transform: translate(0, -50%);
        background-color: var(--v-grey-lighten3);
    }
    &__row{
        position: relative;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 8px;
            height: 8px;
            top: 8px;
            left: -22px;
            background-color: var(--v-primary-lighten5);
            border: 1px solid var(--v-primary-base);
        }
        &:last-child{
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 8px;
                height: calc(100% - 12px);
                bottom: 0;
                left: -22px;
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .history{
        &::before{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__row{
            position: relative;
            &::after{
                top: 10px;
                left: 50%;
                transform: translateX(-50%);
            }
            &:last-child{
                &::before{
                    height: calc(100% - 14px);
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        &__row{
            &:nth-child(2n - 1){
                .history__tit{
                    text-align: right;
                }
            }
            &:nth-child(2n){
                &,
                .history__year-row,
                .history__info-row{
                    flex-direction: row-reverse;       
                }
                .history__year,
                .history__info-row{
                    text-align: right;
                }
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>