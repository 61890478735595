var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notification ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.notification.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.작성일')) + " : " + _vm._s(_vm.notification.createdAt.toDate()))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.조회수')) + " : " + _vm._s(_vm.notification.viewCount))])])], 1)], 1)]), _vm.notification.upload ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.attachments")) + " : "), _c('v-icon', [_vm._v("mdi-paperclip")]), _c('a', {
    staticStyle: {
      "text-decoration": "underline"
    },
    on: {
      "click": function ($event) {
        _vm.download(_vm.notification.upload, _vm.notification.upload.name.split('/').pop());
      }
    }
  }, [_vm._v(_vm._s(_vm.notification.upload.name.split('/').pop()))])], 1)]) : _vm._e(), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.notification.content.replace(/\n/g, '<br>'))
    }
  })])])]), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-tertiary', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }