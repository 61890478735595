var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-container', [_c('v-tabs', {
    attrs: {
      "hide-slider": ""
    }
  }, [_c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.setCatgory(null);
      }
    }
  }, [_vm._v("ALL")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.setCatgory('INT');
      }
    }
  }, [_vm._v("INT+OFFICE")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.setCatgory('OFFICE');
      }
    }
  }, [_vm._v("OFFICE")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.setCatgory('EDUCATION');
      }
    }
  }, [_vm._v("PUBLIC·EDUCATION")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.setCatgory('MEDICAL');
      }
    }
  }, [_vm._v("MEDICAL")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.setCatgory('DORMITORY');
      }
    }
  }, [_vm._v("DORMITORY")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }