<template>
<div class="board-container">
    <div class="board-list-faq">
        <v-expansion-panels accordion>
            <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
                <v-expansion-panel-header>
                    <div class="board-list-faq__header">
                        <div class="board-list-faq__header-mark">
                            <span>Q</span>
                        </div>
                        <div class="board-list-faq__tit">
                            <!-- <span class="board-list-faq__category">{{ $t(`faq.type['${faq.type}']`) }}</span> -->
                            <span>{{ faq.subject }}</span>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="board-list-faq__content">
                        <div class="board-list-faq__content-mark"><span>A</span></div>
                        <div class="board-list-faq__txt">
                            <span v-html="faq.content.replace(/\n/g, '<br>')" class="board-list-faq__html"></span>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</div>
</template>

<script>
import api from "@/api";

export default{
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            filter: {
                type: "회원서비스",
            },
            faqs: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(){
            try{
                var { summary, faqs } = await api.v1.center.faqs.gets({
                    params: this.filter
                });

                this.faqs = faqs;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    }
}
</script>

<style scoped>
/* Board Style - FAQ */
.board-list-faq{
    border-top:1px solid var(--v-primary-base);
    border-bottom:1px solid var(--v-primary-base);
}
.board-list-faq .v-expansion-panel:before{
    box-shadow: none !important;
}
.board-list-faq .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after{
    border-color:var(--v-primary-base);
}
.board-list-faq .v-expansion-panel-header{
    min-height:54px;
    padding:0 24px 0 0;
}
.board-list-faq__header{
    display:flex;
    flex-wrap:wrap;
    font-size: 1.6rem;
}
.board-list-faq__header-mark{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    font-size: 1.6rem;
    font-weight: 700;
    color:var(--v-primary-base);
}
.board-list-faq__tit{
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    width: calc(100% - 54px);
    font-size: 1.6rem;
    font-weight: 700;
    color:#222;
}
.board-list-faq .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
    color: #686868;
}
.board-list-faq .v-expansion-panel-content__wrap{
    padding:0;
}
.board-list-faq__content{
    display: flex;
    flex-wrap: wrap;
    padding: 24px 24px 24px 54px;
    background: #efefef;
}
.board-list-faq__content-mark{
    width: 54px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    color: #686868;
    margin-left: -54px;
}
.board-list-faq__txt{
    width: 100%;
}
.board-list-faq__txt, .board-list-faq__txt >*{
    font-size: 1.4rem;
    color:#686868;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .board-list-faq .v-expansion-panel-header{
        min-height:64px;
        padding:0 20px 0 0;
    }
    .board-list-faq__header-mark{
        width: 64px;
        height: 64px;
        font-size: 1.8rem;
    }
    .board-list-faq__tit{
        width: calc(100% - 64px);
        font-size: 1.8rem;
    }
    .board-list-faq__content{
        padding: 24px 24px 24px 64px;
    }
    .board-list-faq__content-mark{
        font-size: 1.8rem;
        width:64px;
        margin-left: -64px;
        /* width: 20px;
        font-size: 1.6rem;
        text-align: left;
        margin-left: 0; */
    }
    .board-list-faq__txt{
        width: calc(100% - 20px);
    }
    .board-list-faq__txt, .board-list-faq__txt >*{
        font-size: 1.6rem;
    }
}
@media (min-width:1200px){
}
</style>