<template>
	<client-page>

        <template slot="subHead">
            <sub-visual sh="Customer Service" tabActive="공지사항" bg="/images/sub/visual/sv-service.jpg" />
        </template>

        <page-section class="page-section--first page-section--last">
            <notification-list v-if="!$route.params._notification"></notification-list>
            <notification-view v-else></notification-view>
        </page-section>
	</client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default{
	components: {
        ClientPage,
        SubVisual,
        PageSection,
		NotificationList,
		NotificationView
	},
}
</script>
