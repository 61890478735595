<template>
    <v-dialog v-model="show" max-width="944">        
        <v-card tile flat>
            <v-toolbar>
                <v-toolbar-title class="d-flex align-center">
                    <txt class="txt--dark font-secondary">
                        <!--  3depth title  -->
                        <strong>{{ series }} / </strong> 
                        <!--  4depth title  -->
                        {{ item?.subject }}
                    </txt>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon class="transparent" @click="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
                <v-card-text class="pa-12px py-20px pa-md-30px pa-lg-40px">
                    <div class="mb-n20px mb-md-n40px">                    
                        <template v-for="image, index in images">
                            <div v-if="image.image" :key="index" class=" mb-20px mb-md-40px">
                                <!-- 상세이미지 -->
                                <v-img :src="image?.image?.src" class="w-100" />
                            </div>                                
                        </template>
                    </div>
                </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Tit,
        Txt,
    },
    data() {
        return {
            show: false,
            item: [],
            series: null
        };
    },
    methods:{
        open(series, item, index){
            this.series = series
            this.item = item
            this.images = item.products[index].images
            console.log(this.images);
            this.show = true
        },
        close(){
            this.show = false
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
    .v-toolbar,
    .v-toolbar__content{
        min-height: 56px;
        height: auto !important;
    }
    .v-toolbar__title{
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
    }
}
</style>