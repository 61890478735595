<template>
    <v-bottom-sheet eager v-model="show" fullscreen max-width="100%" content-class="mw-100 h-100 grey darken-4" width="100%">
        <div class="reference-view">
            <div class="reference-view__slide">
            <swiper ref="swiperTop" :options="swiperOptionTop" class="swiper swiper--main">
                <!-- S: thumb -->
                <swiper-slide v-for="image, index in board.images" :key="index">
                    <div v-if="typeof(image.image) === 'object'" class="swiper-slide__inner" :style="`background-image: url(${image?.image?.src})`"></div>
                </swiper-slide>
                <!-- E: thumb -->
            </swiper>
            </div>
            <div class="reference-view__thumb-slide">
                <v-container class="container--lg">                    
                    <swiper ref="swiperThumbs" :options="swiperOptionThumbs" class="swiper swiper--thumb">
                        <!-- S: thumb -->
                        <swiper-slide v-for="image, index in board.images" :key="index">
                            <v-responsive v-if="typeof(image.image) === 'object'" :aspect-ratio="1920 / 980" class="swiper-slide__thumb w-100 image-card" :style="`background-image: url(${image?.image?.src})`"></v-responsive>
                        </swiper-slide>
                        <!-- E: thumb -->
                    </swiper>
                </v-container>
            </div>
            <div class="reference-view__contents pt-30px pt-md-60px">
                <v-container class="container--lg">
                    <v-row>
                        <v-col cols="12" lg="5" class="mb-20px mb-md-30px mb-lg-0">
                            <!-- tit eng -->
                            <txt class="white--text line-height-15 mb-6px mb-md-12px">{{ board?.ensubject }}</txt>
                            <!-- tit kor -->
                            <tit class="white--text font-secondary mb-16px mb-md-24px">{{ board?.subject }}</tit>
                            <v-row class="row--xs">
                                <v-col cols="auto">
                                    <btn-arrow-primary color="white" class="swiper-btn-prev"/>
                                </v-col>
                                <v-col cols="auto">
                                    <btn-arrow-primary color="white" direction="right" class="swiper-btn-next"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" lg="7">
                            <v-row justify-lg="end" class="row--sm mx-lg-n20px">
                                <v-col cols="12" md="auto" class="px-lg-20px">
                                    <v-row align="center" class="row--xs">
                                        <v-col cols="auto">
                                            <txt class="txt--xs white--text">업종</txt>
                                        </v-col>
                                        <v-col cols="auto">
                                            <span class="line"></span>
                                        </v-col>
                                        <v-col cols="auto">
                                            <!-- 업종 -->
                                            <txt class="txt--xs grey--text text--lighten-3">{{ board?.sectors }}</txt>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="auto" class="px-lg-20px">
                                    <v-row align="center" class="row--xs">
                                        <v-col cols="auto">
                                            <txt class="txt--xs white--text">구성공간</txt>
                                        </v-col>
                                        <v-col cols="auto">
                                            <span class="line"></span>
                                        </v-col>
                                        <v-col cols="auto">
                                            <!-- 구성공간 -->
                                            <txt class="txt--xs grey--text text--lighten-3">{{ board?.construction }}</txt>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="auto" class="px-lg-20px">
                                    <v-row align="center" class="row--xs">
                                        <v-col cols="auto">
                                            <txt class="txt--xs white--text">적용제품</txt>
                                        </v-col>
                                        <v-col cols="auto">
                                            <span class="line"></span>
                                        </v-col>
                                        <v-col cols="auto">
                                            <!-- 적용제품 -->
                                            <txt class="txt--xs grey--text text--lighten-3">{{ board?.product }}</txt>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="auto" class="px-lg-20px">
                                    <v-row align="center" class="row--xs">
                                        <v-col cols="auto">
                                            <txt class="txt--xs white--text">시공일자</txt>
                                        </v-col>
                                        <v-col cols="auto">
                                            <span class="line"></span>
                                        </v-col>
                                        <v-col cols="auto">
                                            <!-- 시공일자 -->
                                            <txt class="txt--xs grey--text text--lighten-3">{{ board?.constructedAt }}</txt>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <v-btn icon fixed right dark @click="close" class="transparent v-size--xx-large"><v-icon>mdi-close</v-icon></v-btn>
    </v-bottom-sheet>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,

        BtnArrowPrimary,
        Tit,
        Txt,
    },
    data() {
        return {
            show: false,
            board: {},

            swiperOptionTop: {
                effect: "fade",
                thumbs: {
                    swiper: this.$refs.swiperThumbs,
                },
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                allowTouchMove: true,
                speed: 500,
                navigation: {
                    nextEl: ".swiper-btn-next",
                    prevEl: ".swiper-btn-prev",
                },
            },
            swiperOptionThumbs: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                allowTouchMove: true,
                slidesPerView: 5,
                spaceBetween: 8,
                slideToClickedSlide: true,
                watchSlidesProgress: true,
                speed: 500,
                breakpoints: {
                    768: {
                        spaceBetween: 12,
                    },
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                const swiperTop = this.$refs.swiperTop.$swiper;
                const swiperThumbs = this.$refs.swiperThumbs.$swiper;
                swiperTop.controller.control = swiperThumbs;
                swiperThumbs.controller.control = swiperTop;
            });
        },
        open(board){
            this.board = board;
            this.show = true
        },
        close(){
            this.show = false
        }
    },
}
</script>

<style lang="scss" scoped>
.reference-view{
    position: relative;
    height: 100vh;
    &__slide{
        .swiper-slide__inner{
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &__thumb-slide{
        position: absolute;
        width: 100%;
        padding: 32px 0;
        left: 0;
        bottom: 0;
        z-index: 5;
        background-color: rgba(0, 0, 0, .3);
        .swiper-container{
            max-width: 768px;
        }
        .swiper-slide__thumb{
            position: relative;
            cursor: pointer;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .3);
                top: 0;
                left: 0;
                transition: 0.1s ease-out;
            }
        }
        .swiper-slide-active{            
            .swiper-slide__thumb{
                position: relative;
                &::after{
                    background-color: transparent;
                    border: 1px solid #fff;
                }
            }
        }
    }
    &__contents{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 5;
    }
}
.line{
    display: block;
    width: 1px;
    height: 12px;
    background-color: rgba(255, 255, 255, .3);
}

::v-deep{
    .v-btn--fixed.v-btn--right {
        z-index: 99;
        top: 6px;
        right: 6px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    ::v-deep{
        .v-btn--fixed.v-btn--right {
            top: 12px;
            right: 12px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>