<template>
    <v-card v-bind="$attrs" flat>
        <div class="pa-16px pa-md-24px">
            <v-row class="row--xs">
                <v-col>
                    <txt data-aos="fade-up" class="font-weight-medium line-height-1 primary--text">0{{index + 1}}</txt>
                </v-col>
                <v-col cols="auto">
                    <v-img data-aos="zoom-out" data-aos-delay="150" :src="icon" max-width="36" eager contain :aspect-ratio="1 / 1" />
                </v-col>
            </v-row>
            <div class="mt-16px mt-md-32px">
                <txt data-aos="fade-up" data-aos-delay="100" class="txt--dark font-weight-medium break-keep">{{title}}</txt>
                <txt data-aos="fade-up" data-aos-delay="150" v-if="sc" class="font-secondary mt-4px mt-md-8px">{{sc}}</txt>
            </div>
        </div>
    </v-card>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Tit,
        Txt,
    },
    props: {
        index: { type: Number, default: "" },
        title: { type: String, default: "" },
        sc: { type: String, default: "" },
        icon: { type: String, default: "" },
    },
}
</script>

<style>

</style>