var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "subHead"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "Customer Service",
      "tabActive": "온라인견적문의",
      "bg": "/images/sub/visual/sv-service.jpg"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('tit-wrap-secondary', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "TxtAdd",
      fn: function () {
        return [_vm._v(" 오피스 환경 개선부터 병원, 교육기관, 호텔까지 다양한 공간에 대한 고민을 해결해 드립니다. ")];
      },
      proxy: true
    }])
  }, [_vm._v(" VIITZ의 공간 컨설팅 전문기업과 함께하세요. ")])], 1), _c('page-section', {
    style: _vm.$vuetify.breakpoint.mdAndUp ? 'background-image: url(/images/sub/service/inquiry/inquiry-bg.jpg)' : 'background-image: url(/images/sub/service/inquiry/inquiry-bg-mo.jpg)'
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm text-center text-md-left",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('tit', {
    staticClass: "tit--sm white--text"
  }, [_vm._v("견적/구매 문의 프로세스")])], 1), _c('v-img', {
    staticClass: "w-100 d-none d-md-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/service/inquiry/inquiry-img.svg",
      "max-width": "1200",
      "aspect-ratio": 1200 / 240,
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "w-100 mx-auto d-md-none",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/service/inquiry/inquiry-img-mo.svg",
      "max-width": "240",
      "aspect-ratio": 240 / 1200,
      "contain": ""
    }
  })], 1), _c('page-section', {
    staticClass: "pb-0"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("문의처")])], 1), _c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px px-lg-60px py-lg-40px"
  }, [_c('v-row', {
    staticClass: "mx-lg-n20px mx-xl-n50px"
  }, [_c('v-col', {
    staticClass: "px-lg-20px px-xl-50px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs line-height-1 mb-4px mb-md-8px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v("대표 전화번호")]), _c('txt', {
    staticClass: "txt--dark font-weight-medium",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('a', {
    attrs: {
      "href": "tel:1577-6536",
      "target": "_blank"
    }
  }, [_vm._v("1577-6536")])])], 1), _c('v-col', {
    staticClass: "px-lg-20px px-xl-50px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs line-height-1 mb-4px mb-md-8px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v("상담 가능 요일")]), _c('txt', {
    staticClass: "txt--dark font-weight-medium font-secondary",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v("매주 월~금요일 09:00 ~ 17 :00")])], 1), _c('v-col', {
    staticClass: "px-lg-20px px-xl-50px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs line-height-1 mb-4px mb-md-8px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v("점심시간")]), _c('txt', {
    staticClass: "txt--dark font-weight-medium font-secondary",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v("11:30 ~ 13:00")])], 1), _c('v-col', {
    staticClass: "px-lg-20px px-xl-50px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs line-height-1 mb-4px mb-md-8px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v("휴무")]), _c('txt', {
    staticClass: "txt--dark font-weight-medium font-secondary",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v("토/일/공휴일 상담실 휴무")])], 1)], 1)], 1)])], 1), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("견적/구매 문의")])], 1), _c('form-input', {
    attrs: {
      "skin": "purchase",
      "code": "estimate"
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }