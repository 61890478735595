var render = function render(){
  var _vm$item;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "944"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('v-toolbar', [_c('v-toolbar-title', {
    staticClass: "d-flex align-center"
  }, [_c('txt', {
    staticClass: "txt--dark font-secondary"
  }, [_c('strong', [_vm._v(_vm._s(_vm.series) + " / ")]), _vm._v(" " + _vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.subject) + " ")])], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "transparent",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-12px py-20px pa-md-30px pa-lg-40px"
  }, [_c('div', {
    staticClass: "mb-n20px mb-md-n40px"
  }, [_vm._l(_vm.images, function (image, index) {
    var _image$image;
    return [image.image ? _c('div', {
      key: index,
      staticClass: "mb-20px mb-md-40px"
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": image === null || image === void 0 ? void 0 : (_image$image = image.image) === null || _image$image === void 0 ? void 0 : _image$image.src
      }
    })], 1) : _vm._e()];
  })], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }