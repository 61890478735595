var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "subHead"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "Customer Service",
      "tabActive": "공지사항",
      "bg": "/images/sub/visual/sv-service.jpg"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [!_vm.$route.params._notification ? _c('notification-list') : _c('notification-view')], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }