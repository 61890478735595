<template>
    <client-page>

        <template slot="contentsImmersive">
            <div class="reference-view__head">
                <div class="reference-view__head-bg" :style="`background-image: url(${board?.image?.src})`"></div>
                <v-container>
                    <div class="reference-view__head-tit">
                        <!--  3depth title  -->
                        <tit class="tit--xl white--text">{{ board?.series }}</tit>
                    </div>
                </v-container>
                <div class="scroll-ani"><span class="scroll-ani__inner"></span></div>
            </div>
        </template>

        <page-section class="page-section--first">
            <tit-wrap-tertiary class="pb-0">
                <!--  3depth title  -->
                {{ board?.series }}
                <!--  3depth description  -->
                <template #TxtAdd>
                    <div v-html="board?.introduction" />
                </template>
            </tit-wrap-tertiary>
            <div data-aos="fade-up" data-aos-delay="150" class="mt-20px mt-md-40px">
                <v-row justify="center" class="row--sm">
                    <v-col cols="auto">
                        <!-- <btn-tertiary @click="$router.go(-1)">리스트</btn-tertiary> -->
                        <btn-tertiary to="/product">리스트</btn-tertiary>
                    </v-col>
                    <v-col cols="auto">                    
                        <btn-secondary to="/service/inquiry">견적문의</btn-secondary>
                    </v-col>
                </v-row>
            </div>
        </page-section>

        <page-section class="page-section--last grey lighten-5">
            <div class="mb-n60px mb-lg-n100px">
                <!-- S: 4depth -->
                <div v-for="item, index in board.items" :key="index" class="mb-60px mb-lg-100px">
                    <!--  4depth title  -->
                    <tit-wrap-quarternary>{{ item?.subject }}</tit-wrap-quarternary>
                    <v-row class="row--xl my-n24px my-md-n20px">
                        <!-- S: 5depth -->
                        <v-col v-for="product, index in item.products" :key="index" cols="12" lg="6" class="py-24px py-md-20px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-card outlined tile flat color="reference-view__thumb-card thumb-btn-wrap" @click="$refs.product.open(board.series, item, index)">
                                        <div class="thumb-btn">
                                            <!-- 5depth thumb -->
                                            <v-img :src="product?.image?.src" :aspect-ratio="1 / 1" class="w-100" />                                    
                                            <u-btn-icon-flat-tile color="primary" class="v-btn--none-active-bg"><v-icon>mdi-plus</v-icon></u-btn-icon-flat-tile>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-card outlined tile flat class="h-100">
                                        <div class="pa-12px w-100 h-100 white">
                                            <v-row class="ma-n1px row-cols-5">
                                                <v-col v-for="color, index in product._colors" :key="index" class="pa-1px">
                                                    <v-card outlined tile flat>
                                                        <!-- 4depth color option -->
                                                        <v-responsive :aspect-ratio="1 / 1" class="w-100 image-card" content-class="d-flex align-center justify-center" :style="`background-image: url(${color?.image?.src})`">
                                                            <span class="reference-view__color-title">{{ color.code }}</span>
                                                        </v-responsive>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <div class="pt-16px pt-md-24">
                                <!--  5depth title  -->
                                <txt class="txt--dark font-weight-medium font-secondary">
                                    {{ product?.name }}
                                </txt>
                            </div>
                            <div class="pt-16px pt-md-24 mb-n8px mb-md-n12px">
                                <!-- S: product-item -->
                                <div v-for="item, index in product.items" :key="index">
                                    <v-row align="center" class="row--xs">
                                        <v-col cols="auto">
                                            <!-- product title -->
                                            <txt class="txt--xs txt--dark line-height--1">{{ item?.code }}</txt>
                                        </v-col>
                                        <v-col>
                                            <!-- product size -->
                                            <txt class="txt--xs txt--light line-height-1">{{ item?.size }}</txt>
                                        </v-col>
                                        <v-col cols="auto">
                                            <!-- product price -->
                                            <txt class="txt--xs primary--text line-height-1 text-right">￦ {{ item?.price?.format() }}</txt>                                
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-8px my-md-12px grey lighten-3" /> 
                                </div>
                                <!-- E: product-item -->
                            </div>
                        </v-col>
                        <!-- E: 5depth -->
                    </v-row>
                </div>
                <!-- E: 4depth -->
            </div>
        </page-section>

        <product-popup-view ref="product" />
        
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/sets/styles/apps/client-page.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import TitWrapTertiary from "@/components/publish/parents/typography/tit-wrap-tertiary.vue";
import TitWrapQuarternary from "@/components/publish/parents/typography/tit-wrap-quarternary.vue";
import ProductPopupView from "@/components/client/product/product-popup-view.vue";

import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";
import BtnTertiary from "@/components/publish/parents/buttons/btn-tertiary.vue";
import UBtnIconFlatTile from "@/components/publish/styles/buttons/u-btn-icon-flat-tile.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapTertiary,
        TitWrapQuarternary,
        ProductPopupView,

        BtnSecondary,
        BtnTertiary,
        UBtnIconFlatTile,
        Tit,
        Txt,
    },
    props: {

    },
    data() {
        return {
            board: {}
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { board } = await api.v1.boards.get({ _id: this.$route.params._board });
            this.board = board;
        }
    }
};
</script>

<style lang="scss" scoped>
.reference-view{
    &__head{
        padding-top: var(--header-body-height);
        padding-bottom: 80px;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &-bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &-tit{
            width: fit-content;
            min-height: 86px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            text-align: center;
            position: relative;
            padding: 16px 48px;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border: 1px solid #fff;
                opacity: 0;
                visibility: hidden;      
                transform: scaleX(0);
                animation: scaleX 0.6s 0.3s forwards ease-out;
            }
            h2{
                position: relative;
                opacity: 0;
                visibility: hidden;                
                transform: scale(1.4);
                animation: zoomOut 0.6s forwards ease-in-out;
            }
        }
        .container{
            position: relative;
        }
    }
    &__color-title{
        width: fit-content;
        padding: 2px;
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        font-size: 1rem;
        line-height: 1;
    }
}
@keyframes zoomOut {
    0%{
        opacity: 0;
        visibility: hidden;                
        transform: scale(1.4);
    }
    100%{
        opacity: 1;
        visibility: visible;                
        transform: scale(1);
    }
}
@keyframes scaleX {
    0%{
        opacity: 0;
        visibility: hidden;  
        transform: scaleX(0);
    }
    100%{
        opacity: 1;
        visibility: visible;    
        transform: scaleX(1);
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .reference-view{
        &__head{
            padding-bottom: 48px;
            &-tit{
                min-height: 120px;
            }
        }
    }
}
@media (min-width:1024px){
    .reference-view{
        &__head{
            &-tit{
                min-height: 140px;
            }
        }
    }
}
@media (min-width:1200px){
}

.scroll-ani{
    position: absolute;
    width: 1px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.3);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
        &__inner{
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                background-color: #fff;
                opacity: 0;
                visibility: hidden;  
                top: 0;
                left: 0;
                animation: scrollAni 1.2s infinite linear;
            }
        }
}

@keyframes scrollAni {
    0%{
        opacity: 0;
        visibility: hidden;  
        top: 0;
    }
    20%{
        opacity: 1;
        visibility: visible;         
    }
    80%{
        opacity: 1;
        visibility: visible;         
    }
    100%{
        opacity: 0;
        visibility: hidden; 
        top: 50%; 
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .scroll-ani{
        height: 80px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
