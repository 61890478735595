<template>
	<client-page>
        <sub-visual sh="게시판" tabActive="FAQ" bg="/images/test.jpg" />
        <page-section>
            <faq-list></faq-list>
        </page-section>
	</client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import FaqList from "@/components/client/center/faqs/faq-list.vue";

export default{
	components: {
        ClientPage,
        SubVisual,
        PageSection,
		FaqList,
    },
}
</script>
