<template>
    <client-page>

        <template slot="subHead">
            <sub-visual sh="Customer Service" tabActive="E-카다로그" bg="/images/sub/visual/sv-service.jpg" />
        </template>

        <page-section class="page-section--first page-section--last grey lighten-5">
            <tit-wrap-secondary>
                E-카다로그 다운로드
            </tit-wrap-secondary>
            <v-img data-aos="zoom-out" data-aos-delay="100" src="/images/sub/service/catalog/catalog-img.png" max-width="400" :aspect-ratio="400 / 566" class="w-100 elevation-10 mx-auto" />
            <div data-aos="fade-up" data-aos-delay="100" class="btn-wrap">
                <v-row justify="center" class="row--sm">
                    <v-col cols="auto">
                        <u-btn-outlined-tile href="/file/catalog.pdf" target="_blank" color="primary" class="min-w-120px min-w-md-160px justify-space-between">
                            PDF 보기                             
                            <u-icon class="v-icon--right">add</u-icon>
                        </u-btn-outlined-tile>
                    </v-col>
                    <v-col cols="auto">
                        <u-btn-outlined-tile href="/file/catalog.pdf" download="vol02_비츠_카탈로그.pdf" color="primary" class="min-w-120px min-w-md-160px justify-space-between">
                            PDF 다운로드 <icon-arrow direction="down" />
                        </u-btn-outlined-tile>                        
                    </v-col>
                </v-row>
            </div>
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import UBtnOutlinedTile from "@/components/publish/styles/buttons/u-btn-outlined-tile.vue";
import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        TitWrapSecondary,
        UBtnOutlinedTile,
        IconArrow,
        UIcon,
        Tit,
        Txt,
    },
}
</script>

<style lang="scss" scoped>
::v-deep{
    .v-btn{
        .v-icon {
            font-size: 16px !important;
        }
    }
}
</style>