var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "my-n10px my-md-n20px"
  }, _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      staticClass: "py-10px py-md-20px",
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "v-card--none-active-bg thumb-btn-wrap",
      attrs: {
        "to": `/product_view/${board._id}`,
        "flat": "",
        "tile": ""
      }
    }, [_c('div', {
      staticClass: "thumb-btn"
    }, [_c('v-card', {
      attrs: {
        "flat": "",
        "outlined": "",
        "tile": ""
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": board.thumb,
        "max-width": "384",
        "aspect-ratio": 384 / 256
      }
    })], 1), _c('btn-arrow-secondary', {
      staticClass: "primary v-btn--none-active-bg"
    })], 1), _c('div', {
      staticClass: "pt-16px pt-md-24px"
    }, [_c('txt', {
      staticClass: "primary--text line-height-1 txt--xs mb-4px mb-md-8px"
    }, [_vm._v(_vm._s(board.category2))]), _c('txt', {
      staticClass: "txt--dark font-weight-medium"
    }, [_vm._v(_vm._s(board.category3))])], 1)])], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }