var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "my-n10px my-md-20px"
  }, _vm._l(_vm.boards, function (board) {
    var _board$thumb;
    return _c('v-col', {
      key: board === null || board === void 0 ? void 0 : board._id,
      staticClass: "py-10px py-md-20px",
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "thumb-btn-wrap h-100",
      attrs: {
        "outlined": "",
        "flat": "",
        "tile": ""
      },
      on: {
        "click": function ($event) {
          return _vm.$refs.reference.open(board);
        }
      }
    }, [_c('div', {
      staticClass: "white h-100"
    }, [_c('div', {
      staticClass: "thumb-btn"
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": board === null || board === void 0 ? void 0 : (_board$thumb = board.thumb) === null || _board$thumb === void 0 ? void 0 : _board$thumb.src,
        "aspect-ratio": 280 / 186
      }
    }), _c('u-btn-icon-flat-tile', {
      staticClass: "v-btn--none-active-bg",
      attrs: {
        "color": "primary"
      }
    }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('div', {
      staticClass: "pa-16px pa-md-24px"
    }, [_c('txt', {
      staticClass: "txt--xs txt--light text-truncate line-height-1 mb-4px mb-md-8px"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.ensubject))]), _c('txt', {
      staticClass: "txt--dark font-weight-medium text-truncate"
    }, [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.subject))])], 1)])])], 1);
  }), 1), _c('reference-popup-view', {
    ref: "reference"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }