<template>
    <client-page>

        <template slot="subHead">
            <sub-visual sh="Product" bg="/images/sub/visual/sv-product.jpg" />
        </template>
        <div v-scroll="onScrollFixed" class="scroll-fixed-tab w-100">
            <scrollactive v-if="chair" :highlightFirstItem="true" active-class="v-tab--active" class="scroll-fixed-tab__inner v-tabs">
                <router-link to="#workstation" class="scrollactive-item v-tab">workstation</router-link>
                <router-link to="#laymon" class="scrollactive-item v-tab">laymon</router-link>
                <router-link to="#executive" class="scrollactive-item v-tab">executive</router-link>
                <router-link to="#conference" class="scrollactive-item v-tab">conference</router-link>
                <router-link to="#partition" class="scrollactive-item v-tab">partition</router-link>
                <router-link to="#sofa" class="scrollactive-item v-tab">sofa</router-link>
                <router-link to="#chair" class="scrollactive-item v-tab">chair</router-link>
            </scrollactive>
        </div>

        <page-section id="workstation" class="page-section--sm page-section--first">

            <tit-wrap-quarternary>workstation</tit-wrap-quarternary>

            <!-- 1depth list -->
            <product-list :boards="workstation"/>
            
        </page-section>

        <page-section id="laymon" class="page-section--sm">

            <tit-wrap-quarternary>laymon</tit-wrap-quarternary>

            <!-- 1depth list -->
            <product-list :boards="laymon"/>
            
        </page-section>

        <page-section id="executive" class="page-section--sm">

            <tit-wrap-quarternary>executive</tit-wrap-quarternary>

            <!-- 1depth list -->
            <product-list :boards="executive"/>
            
        </page-section>

        <page-section id="conference" class="page-section--sm">

            <tit-wrap-quarternary>conference</tit-wrap-quarternary>

            <!-- 1depth list -->
            <product-list :boards="conference"/>
            
        </page-section>

        <page-section id="partition" class="page-section--sm">

            <tit-wrap-quarternary>partition</tit-wrap-quarternary>

            <!-- 1depth list -->
            <product-list :boards="partition"/>
            
        </page-section>

        <page-section id="sofa" class="page-section--sm">

            <tit-wrap-quarternary>sofa</tit-wrap-quarternary>

            <!-- 1depth list -->
            <product-list :boards="sofa"/>
            
        </page-section>

        <page-section id="chair" class="page-section--sm page-section--last">

            <tit-wrap-quarternary>chair</tit-wrap-quarternary>

            <!-- 1depth list -->
            <product-list :boards="chair"/>
            
        </page-section>

    </client-page>
</template>

<script>
import api from '@/api';
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import ProductList from "@/components/client/product/product-list.vue";

import TitWrapQuarternary from "@/components/publish/parents/typography/tit-wrap-quarternary.vue";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        ProductList,

        TitWrapQuarternary,
        BtnArrowPrimary,
        Tit,
        Txt,
    },
    props: {

    },
    data() {
        return {
            limit: 9999,
            workstation: null,
            laymon: null,
            executive: null,
            conference: null,
            partition: null,
            sofa: null,
            chair: null
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { boards: workstation } = await api.v1.boards.gets({
                    params: { code: "product", category1: "WORKSTATION" },
            })
            this.workstation = workstation;
            
            let { boards: laymon } = await api.v1.boards.gets({
                    params: { code: "product", category1: "LAYMON" },
            })
            this.laymon = laymon;

            let { boards: executive } = await api.v1.boards.gets({
                    params: { code: "product", category1: "EXECUTIVE" },
            })
            this.executive = executive;

            let { boards: conference } = await api.v1.boards.gets({
                    params: { code: "product", category1: "CONFERENCE" },
            })
            this.conference = conference;

            let { boards: partition } = await api.v1.boards.gets({
                    params: { code: "product", category1: "PARTITION" },
            })
            this.partition = partition;

            let { boards: sofa } = await api.v1.boards.gets({
                    params: { code: "product", category1: "SOFA" },
            })
            this.sofa = sofa;

            let { boards: chair } = await api.v1.boards.gets({
                    params: { code: "product", category1: "CHAIR" },
            })
            this.chair = chair;
        },
        onScrollFixed: function() {
            let scrollPosition = 0,
                mobileWidth = 1200;

            let tabsWrap = document.querySelector(".scroll-fixed-tab"),
                tabs = document.querySelector(".scroll-fixed-tab .v-tabs"),
                absoluteTop = 0,
                headerHeight = 56;
            if (window.innerWidth > mobileWidth) {
                headerHeight = 100;
            }

            scrollPosition = window.scrollY || window.pageYOffset;
            absoluteTop = window.pageYOffset + tabsWrap.getBoundingClientRect().top - headerHeight;

            if (scrollPosition < absoluteTop) {
                tabs.classList.remove("active");
            } else {
                tabs.classList.add("active");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-tab{
    text-transform: uppercase;
}
</style>
