var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "subHead"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "Product",
      "bg": "/images/sub/visual/sv-product.jpg"
    }
  })], 1), _c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScrollFixed,
      expression: "onScrollFixed"
    }],
    staticClass: "scroll-fixed-tab w-100"
  }, [_vm.chair ? _c('scrollactive', {
    staticClass: "scroll-fixed-tab__inner v-tabs",
    attrs: {
      "highlightFirstItem": true,
      "active-class": "v-tab--active"
    }
  }, [_c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#workstation"
    }
  }, [_vm._v("workstation")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#laymon"
    }
  }, [_vm._v("laymon")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#executive"
    }
  }, [_vm._v("executive")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#conference"
    }
  }, [_vm._v("conference")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#partition"
    }
  }, [_vm._v("partition")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#sofa"
    }
  }, [_vm._v("sofa")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#chair"
    }
  }, [_vm._v("chair")])], 1) : _vm._e()], 1), _c('page-section', {
    staticClass: "page-section--sm page-section--first",
    attrs: {
      "id": "workstation"
    }
  }, [_c('tit-wrap-quarternary', [_vm._v("workstation")]), _c('product-list', {
    attrs: {
      "boards": _vm.workstation
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--sm",
    attrs: {
      "id": "laymon"
    }
  }, [_c('tit-wrap-quarternary', [_vm._v("laymon")]), _c('product-list', {
    attrs: {
      "boards": _vm.laymon
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--sm",
    attrs: {
      "id": "executive"
    }
  }, [_c('tit-wrap-quarternary', [_vm._v("executive")]), _c('product-list', {
    attrs: {
      "boards": _vm.executive
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--sm",
    attrs: {
      "id": "conference"
    }
  }, [_c('tit-wrap-quarternary', [_vm._v("conference")]), _c('product-list', {
    attrs: {
      "boards": _vm.conference
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--sm",
    attrs: {
      "id": "partition"
    }
  }, [_c('tit-wrap-quarternary', [_vm._v("partition")]), _c('product-list', {
    attrs: {
      "boards": _vm.partition
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--sm",
    attrs: {
      "id": "sofa"
    }
  }, [_c('tit-wrap-quarternary', [_vm._v("sofa")]), _c('product-list', {
    attrs: {
      "boards": _vm.sofa
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--sm page-section--last",
    attrs: {
      "id": "chair"
    }
  }, [_c('tit-wrap-quarternary', [_vm._v("chair")]), _c('product-list', {
    attrs: {
      "boards": _vm.chair
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }