var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "subHead"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "Customer Service",
      "tabActive": "A/S",
      "bg": "/images/sub/visual/sv-service.jpg"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--first pb-0"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("A/S 규정")])], 1), _c('v-row', {
    staticClass: "row--lg mx-lg-n40px"
  }, _vm._l(_vm.rule, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "px-lg-40px",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "grey lighten-5 pa-10px text-center"
    }, [_c('txt', {
      staticClass: "txt--dark font-weight-medium",
      attrs: {
        "data-aos": "zoom-out"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1), _vm._l(item.children, function (child) {
      return _c('div', {
        key: child.title
      }, [_c('v-row', {
        staticClass: "row--sm py-12px py-md-24px"
      }, [_c('v-col', {
        attrs: {
          "data-aos": "fade-right",
          "data-aos-delay": "100",
          "cols": "12",
          "md": "auto"
        }
      }, [_c('div', {
        staticClass: "min-w-120px"
      }, [_c('txt', {
        staticClass: "txt--sm txt--dark font-weight-medium"
      }, [_vm._v(_vm._s(child.title))])], 1)]), _c('v-col', {
        attrs: {
          "data-aos": "fade-left",
          "data-aos-delay": "100"
        }
      }, [_c('txt', {
        staticClass: "txt--sm"
      }, [_vm._v(_vm._s(child.info))])], 1)], 1), _c('v-divider')], 1);
    })], 2);
  }), 1)], 1), _c('page-section', [_c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("유상 서비스 기준")])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.info, function (item, index) {
    return _c('v-col', {
      key: item.title,
      staticClass: "info-card-wrap",
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('u-card-icon', {
      staticClass: "h-100",
      attrs: {
        "outlined": "",
        "index": index,
        "title": item.title,
        "icon": item.icon
      }
    })], 1);
  }), 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm text-center text-md-left"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("A/S 프로세스")])], 1), _c('v-img', {
    staticClass: "w-100 d-none d-md-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/service/as/as-img.svg",
      "max-width": "1200",
      "aspect-ratio": 1200 / 332,
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "w-100 mx-auto d-md-none",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/service/as/as-img-mo.svg",
      "max-width": "312",
      "aspect-ratio": 312 / 1180,
      "contain": ""
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("A/S 요청")])], 1), _c('form-input', {
    attrs: {
      "skin": "as",
      "code": "as"
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }