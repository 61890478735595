var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "subHead"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "Partners",
      "tabActive": "대리점 개설 문의",
      "bg": "/images/sub/visual/sv-partners.jpg"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('tit-wrap-secondary', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "TxtAdd",
      fn: function () {
        return [_vm._v(" 대리점 문의 및 개설안내에 대한 상담은 전화 및 이메일 모두 가능합니다. ")];
      },
      proxy: true
    }])
  }, [_vm._v(" VIITZ와 성공을 함께 할 파트너를 모집합니다. ")])], 1), _c('page-section', {
    style: _vm.$vuetify.breakpoint.mdAndUp ? 'background-image: url(/images/sub/partners/inquiry/inquiry-bg.jpg)' : 'background-image: url(/images/sub/partners/inquiry/inquiry-bg-mo.jpg)'
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm text-center text-md-left"
  }, [_c('tit', {
    staticClass: "tit--sm white--text",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("대리점 개설 프로세스")])], 1), _c('v-img', {
    staticClass: "w-100 d-none d-md-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/partners/inquiry/inquiry-img.svg",
      "max-width": "1200",
      "aspect-ratio": 1200 / 250,
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "w-100 mx-auto d-md-none",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/partners/inquiry/inquiry-img-mo.svg",
      "max-width": "250",
      "aspect-ratio": 250 / 1186,
      "contain": ""
    }
  })], 1), _c('page-section', {
    staticClass: "pb-0"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("담당 사업부 및 당담자")])], 1), _c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px px-lg-60px py-lg-40px"
  }, [_c('v-row', {
    staticClass: "mx-lg-n20px mx-xl-n50px"
  }, [_c('v-col', {
    staticClass: "px-lg-20px px-xl-50px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs line-height-1 mb-4px mb-md-8px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v("담당 사업부")]), _c('txt', {
    staticClass: "txt--dark font-weight-medium",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v("커머셜부 / 오피스팀")])], 1), _c('v-col', {
    staticClass: "px-lg-20px px-xl-50px",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('txt', {
    staticClass: "txt--xs line-height-1 mb-4px mb-md-8px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v("담당자")]), _c('txt', {
    staticClass: "txt--dark font-weight-medium font-secondary",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" 지형준 팀장 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('span', {
    staticClass: "d-none d-md-inline"
  }, [_vm._v(" ( ")]), _vm._v(" HP : "), _c('a', {
    attrs: {
      "href": "tel:01050056035",
      "target": "_blank"
    }
  }, [_vm._v("010-5005-6035")]), _c('span', {
    staticClass: "d-none d-md-inline"
  }, [_vm._v(" / ")]), _vm._v(" "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v(" E-mail : "), _c('a', {
    attrs: {
      "href": "mailto:wlgudwns5801@naver.com",
      "target": "_blank"
    }
  }, [_vm._v("wlgudwns5801@naver.com")]), _c('span', {
    staticClass: "d-none d-md-inline"
  }, [_vm._v(" / ")]), _vm._v(" "), _c('br', {
    staticClass: "d-md-none"
  }), _c('a', {
    staticClass: "d-inline-block pl-56px pl-md-0",
    attrs: {
      "href": "mailto:kkss801@effex.co.kr",
      "target": "_blank"
    }
  }, [_vm._v("kkss801@effex.co.kr")]), _c('span', {
    staticClass: "d-none d-md-inline"
  }, [_vm._v(" ) ")])])], 1)], 1)], 1)])], 1), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v("대리점 개설 문의")])], 1), _c('form-input', {
    attrs: {
      "skin": "agency",
      "code": "agency"
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }