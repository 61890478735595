<template>
    <div class="board-container">        
        <v-row class="row--xs">
            <v-spacer />
            <v-col cols="12" md="auto">
                <v-select v-model="filter.searchKey" :items="searchKeys" hide-details outlined dense class="v-input--density-compact w-100 mw-md-120px"/>
            </v-col>
            <v-col md="auto">
                <v-text-field v-model="filter.searchValue" hide-details outlined dense class="v-input--density-compact w-100 mw-md-240px"/>
            </v-col>
            <v-col cols="auto">
                <v-btn color="grey darken-4" tile dark class="min-w-80px min-w-md-100px" @click="search">검색</v-btn>
            </v-col>
        </v-row>
        <table class="board-list font-secondary mt-12px mt-md-24px">
    		<thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:8%;"></th>
                    <th style="width:48%;">게시물 제목이 옵니다. </th>
                    <th style="width:17%;">작성자</th>
                    <th style="width:17%;">작성일</th>
                    <th style="width:10%;">조회</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notification, index) in fixed" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <v-icon size="18" color="primary">mdi-alert-circle-outline</v-icon>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ notification.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        관리자
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.viewCount.format() }}
                    </td>
                </tr>

                <tr v-for="(notification, index) in notifications" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <span>{{ (page-1)*limit + index + 1 }}</span>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ notification.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        관리자
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.viewCount.format() }}
                    </td>
                </tr>
    		</tbody>
    	</table>
        <div class="pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" />
        </div>

        <div class="btn-wrap btn-wrap--lg">
            <v-row justify="center" class="row--sm">
                <v-col cols="6" sm="auto">
                    <btn-primary to="/service/inquiry">문의하기</btn-primary>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { mdiAlertCircleOutline } from '@mdi/js';

import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
export default {
    components: {
        BtnPrimary,
    },
    props: {
        value: { type: String, default: null }
    },
    data() {
        return {
            mdiAlertCircleOutline,

            filter: { 
                isNotice: false,
                searchKey: null,
                searchValue: null
            },

            searchKeys: [
                { text: "제목", value: "subject" }
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            fixed: [],
            notifications: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init() {
            await this.search();
		},
        async search() {
            this.$router.push({
                query: Object.assign({}, this.query, { page: this.page })
            });

            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: {
                    limit: 0
                },
                params: { isNotice: true }
            });

            this.fixed = fixed;

            var { summary, notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.notifications = notifications;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    }
}
</script>