var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "history mb-n30px mb-md-n60px pl-22px px-md-0"
  }, _vm._l(_vm.history, function (history, index) {
    return _c('v-row', {
      key: index,
      staticClass: "history__row mx-md-n20px mx-lg-n44px mb-30px mb-md-60px",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "px-md-20px px-lg-44px mb-12px mb-md-0",
      attrs: {
        "data-aos": "fade-up",
        "cols": "12",
        "md": "6"
      }
    }, [_c('tit', {
      staticClass: "history__tit tit--sm line-height-1"
    }, [_vm._v(_vm._s(history.title))])], 1), _c('v-col', {
      staticClass: "px-md-20px px-lg-44px mb-n6px mb-md-n12px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "100"
      }
    }, _vm._l(history.year, function (year, index) {
      return _c('v-row', {
        key: index,
        staticClass: "history__year-row pt-md-2px mb-6px mb-md-12px",
        attrs: {
          "no-gutters": ""
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('div', {
        staticClass: "min-w-40px min-w-md-60px mr-8px mr-md-0 history__year"
      }, [_c('txt', {
        staticClass: "txt--sm txt--dark font-secondary font-weight-medium line-height-15"
      }, [_vm._v(_vm._s(year.title))])], 1)]), _c('v-col', _vm._l(year.info, function (detail, index) {
        return _c('v-row', {
          key: index,
          staticClass: "history__info-row row--xxs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt', {
          staticClass: "txt--sm line-height-15"
        }, [_vm._v("·")])], 1), _c('v-col', [_c('txt', {
          staticClass: "txt--sm line-height-15"
        }, [_vm._v(_vm._s(detail))])], 1)], 1);
      }), 1)], 1);
    }), 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }