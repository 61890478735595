<template>
    <div>
        <v-row class="my-n10px my-md-20px">
            <v-col v-for="board in boards" :key="board?._id" cols="6" md="4" lg="3" class="py-10px py-md-20px">
                <v-card outlined flat tile class="thumb-btn-wrap h-100" @click="$refs.reference.open(board)">
                    <div class="white h-100">
                        <div class="thumb-btn">
                            <v-img :src="board?.thumb?.src" :aspect-ratio="280 / 186" class="w-100" />
                            <u-btn-icon-flat-tile color="primary" class="v-btn--none-active-bg"><v-icon>mdi-plus</v-icon></u-btn-icon-flat-tile>
                        </div>
                        <div class="pa-16px pa-md-24px">
                            <txt class="txt--xs txt--light text-truncate line-height-1 mb-4px mb-md-8px">{{ board?.ensubject }}</txt>
                            <txt class="txt--dark font-weight-medium text-truncate">{{ board?.subject }}</txt>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <reference-popup-view ref="reference" />
    </div>
</template>

<script>
import ReferencePopupView from "@/components/client/reference/reference-popup-view.vue";

import UBtnIconFlatTile from "@/components/publish/styles/buttons/u-btn-icon-flat-tile.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {
        boards: { type: Array, default: () => [] }
    },
    components: {
        ReferencePopupView,

        UBtnIconFlatTile,
        Tit,
        Txt,
    },
}
</script>

<style lang="scss" scoped>
.thumb-btn-wrap{
    transition: 0.15s ease-out;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .thumb-btn-wrap:hover{
        border-color: var(--v-primary-base) !important;
    }
}
@media (min-width:1200px){
}

</style>