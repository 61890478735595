var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "subHead"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "About",
      "bg": "/images/sub/visual/sv-about.jpg"
    }
  })], 1), _c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScrollFixed,
      expression: "onScrollFixed"
    }],
    staticClass: "scroll-fixed-tab w-100"
  }, [_c('scrollactive', {
    staticClass: "scroll-fixed-tab__inner v-tabs",
    attrs: {
      "highlightFirstItem": true,
      "active-class": "v-tab--active"
    }
  }, [_c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#brand_story"
    }
  }, [_vm._v("brand story")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#competitivity"
    }
  }, [_vm._v("VIITZ만의 경쟁력")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#company"
    }
  }, [_vm._v("기업 소개")])], 1)], 1), _c('page-section', {
    staticClass: "page-section--first pb-0",
    attrs: {
      "id": "brand_story"
    }
  }, [_c('template', {
    slot: "pageSectionImmersive"
  }, [_c('page-section', {
    staticClass: "pt-0"
  }, [_c('tit-wrap-secondary', {
    staticClass: "pb-0"
  }, [_vm._v(" 고객을 먼저 생각하는 공간 "), _c('br'), _vm._v(" 가치 있는 오피스 가구의 시작 VIITZ ")])], 1), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/about/about-bg.jpg)"
    }
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "data-aos": "fade-up",
      "src": "/images/sub/about/about-img.png",
      "max-width": "660",
      "aspect-ratio": 660 / 318,
      "contain": ""
    }
  })], 1), _c('page-section', [_c('div', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "data-aos": "fade-right",
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/about/about-img2.jpg",
      "max-width": "588",
      "aspect-ratio": 588 / 392
    }
  })], 1), _c('v-col', {
    attrs: {
      "data-aos": "fade-left",
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pl-md-20px pl-lg-40px"
  }, [_c('txt', {
    staticClass: "txt--dark line-height-15 break-keep font-secondary"
  }, [_vm._v(" VIITZ는 실행 가능하고, 완벽한 공간 창조를 제공합니다. "), _c('br'), _c('br'), _vm._v(" 부엌, 인테리어 가구 부문의 역사와 노하우를 바탕으로 탄생한 오피스 브랜드 VIITZ는 고객의 맞춤형 공간을 만들기 위해 끊임없는 연구와 새로운 도전을 통해 품질과 디자인, 서비스에서 고객 감동을 실현합니다. ")])], 1)])], 1)], 1), _c('div', {
    staticClass: "mt-30px mt-md-48px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "data-aos": "fade-left",
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/about/about-img3.jpg",
      "max-width": "588",
      "aspect-ratio": 588 / 392
    }
  })], 1), _c('v-col', {
    attrs: {
      "data-aos": "fade-right",
      "cols": "12",
      "md": "6",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "pr-md-20px pr-lg-40px"
  }, [_c('txt', {
    staticClass: "txt--dark line-height-15 break-keep font-secondary"
  }, [_vm._v(" VIITZ는 Office, Interior, Medical, Education, Public, Dormitory 위 부문의 전문 브랜드 입니다. "), _c('br'), _c('br'), _vm._v(" 40여년의 전통과 경험을 바탕으로 국내 사무용가구의 새로운 역사를 시작한 기업인 VIITZ는 고객 여러분께 만족을 드릴 수 있는 사무공간 창출을 위해 안전하고 트랜드한 제품 생산, 개발에 최선을 다하고 있습니다. "), _c('br'), _c('br'), _vm._v(" VIITZ는 환경친화적인 공간, IT 발전에 발맞춰 미래로 도약하는 공간, 고객의 맞춤형 공간을 만들기 위해 끊임없이 노력하고 있습니다. 다양한 공간의 특성을 분석하고 연구하여 새로운 시도를 통해 최적의 공간, 효율적인 공간, 아름다운 공간을 이루어 내고자 합니다. ")])], 1)])], 1)], 1)]), _c('page-section', {
    staticClass: "grey lighten-5"
  }, [_c('tit-wrap-tertiary', [_vm._v(" 연혁 ")]), _c('about-history')], 1)], 1)], 2), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "id": "competitivity"
    }
  }, [_c('template', {
    slot: "pageSectionImmersive"
  }, [_c('page-section', {
    staticClass: "pt-0"
  }, [_c('tit-wrap-secondary', [_vm._v(" VIITZ만의 경쟁력 ")]), _c('tit-wrap-tertiary', {
    scopedSlots: _vm._u([{
      key: "TxtAdd",
      fn: function () {
        return [_vm._v(" 거점별 Order Made 생산, 가장 빠른 물류시공 System ")];
      },
      proxy: true
    }])
  }, [_vm._v(" 제품 생산/물류/시공 One-Stop 서비스 ")]), _c('v-card', {
    staticStyle: {
      "border-bottom-width": "0 !important"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "border-b",
    class: _vm.$vuetify.breakpoint.mdAndUp ? 'border-r' : '',
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "7"
    }
  }, [_c('div', {
    staticClass: "h-100 pa-20px pa-md-30px pa-lg-40px d-flex justify-center align-center"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/about/about-img4.svg",
      "max-width": "584",
      "aspect-ratio": 584 / 500,
      "contain": ""
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "5"
    }
  }, _vm._l(_vm.info, function (item, index) {
    return _c('u-card-icon', {
      key: item.title,
      staticClass: "border-b",
      staticStyle: {
        "min-height": "calc(100% / 3)"
      },
      attrs: {
        "index": index,
        "title": item.title,
        "sc": item.sc,
        "icon": item.icon
      }
    });
  }), 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "pt-0"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm text-center text-md-left"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("One-Stop 납품 프로세스")])], 1), _c('v-img', {
    staticClass: "w-100 mx-auto d-none d-md-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/about/about-img5.svg",
      "max-width": "1200",
      "aspect-ratio": 1200 / 290,
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "w-100 mx-auto d-md-none",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/about/about-img5-mo.svg",
      "max-width": "350",
      "aspect-ratio": 350 / 930,
      "contain": ""
    }
  })], 1), _c('page-section', {
    staticClass: "pt-0"
  }, [_c('tit-wrap', {
    staticClass: "tit-wrap--sm text-center text-md-left"
  }, [_c('tit', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("One-Stop 납품을 위한 4가지 관리 체계")])], 1), _c('v-img', {
    staticClass: "w-100 mx-auto d-none d-md-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/about/about-img6.svg",
      "max-width": "1200",
      "aspect-ratio": 1200 / 500,
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "w-100 mx-auto d-md-none",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/about/about-img6-mo.svg",
      "max-width": "350",
      "aspect-ratio": 350 / 660,
      "contain": ""
    }
  })], 1), _c('page-section', {
    staticClass: "pt-0"
  }, [_c('v-divider')], 1), _c('page-section', {
    staticClass: "pt-0"
  }, [_c('tit-wrap-tertiary', [_vm._v(" 품질 경쟁력 ")]), _c('v-card', {
    attrs: {
      "flat": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-20px py-lg-40px px-lg-10px"
  }, [_c('v-row', {
    staticClass: "mb-n16px mb-md-0",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.competitivity, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "mb-16px mb-md-0",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "px-md-10px px-lg-50px"
    }, [_c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "data-aos": "fade-up",
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "src": "/images/icon/icon-check.svg",
        "eager": "",
        "max-width": "16",
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('v-col', [_c('txt', {
      staticClass: "txt--xs primary--text"
    }, [_vm._v(_vm._s(item.title))])], 1)], 1), _c('txt', {
      staticClass: "txt--dark font-weight-medium break-keep mt-4px mt-md-8px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "100"
      }
    }, [_vm._v(" " + _vm._s(item.info) + " ")])], 1)]);
  }), 1)], 1)]), _c('div', {
    staticClass: "mt-30px mt-md-60px"
  }, [_c('v-row', _vm._l(_vm.cert, function (detail) {
    return _c('v-col', {
      key: detail,
      attrs: {
        "cols": "4",
        "md": "2"
      }
    }, [_c('v-img', {
      staticClass: "w-100 mx-auto",
      attrs: {
        "data-aos": "fade-up",
        "src": detail,
        "max-width": "180",
        "aspect-ratio": 180 / 248
      }
    })], 1);
  }), 1)], 1)], 1), _c('page-section', {
    staticClass: "pt-0"
  }, [_c('v-divider')], 1), _c('page-section', {
    staticClass: "pt-0 pb-0"
  }, [_c('tit-wrap-tertiary', [_vm._v(" 전문 서비스 ")]), _c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px px-lg-60px py-lg-40px"
  }, [_c('v-row', {
    staticClass: "mx-lg-n20px mx-xl-n50px"
  }, [_c('v-col', {
    staticClass: "px-lg-20px px-xl-50px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs line-height-1 mb-4px mb-md-8px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("01")]), _c('txt', {
    staticClass: "txt--dark font-weight-medium",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(" 자체 개발 ERP system / 비츠 캐드 보유 ")])], 1), _c('v-col', {
    staticClass: "px-lg-20px px-xl-50px",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('txt', {
    staticClass: "txt--xs line-height-1 mb-4px mb-md-8px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("02")]), _c('txt', {
    staticClass: "txt--dark font-weight-medium font-secondary",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(" 공간 컨설팅 (오피스 + INT) / 전문 대리점 • 인력 보유 ")])], 1)], 1)], 1)]), _c('div', {
    staticClass: "mt-30px mt-md-60px"
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "src": "/images/sub/about/about-img8.jpg",
      "max-width": "906",
      "aspect-ratio": 906 / 598,
      "contain": ""
    }
  })], 1)])], 1)], 1)], 1)], 2), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    staticStyle: {
      "background-image": "url(/images/sub/about/about-bg2.jpg)"
    },
    attrs: {
      "id": "company"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-wrap', [_c('tit', {
    staticClass: "tit--lg white--text",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("기업 소개")])], 1), _c('div', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('btn-primary2', {
    attrs: {
      "href": "https://www.effex.co.kr/",
      "target": "_blank"
    }
  }, [_vm._v("이펙스 기업 소개")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-color": "rgba(255, 255, 255, 0.3) !important"
    }
  }), _vm._l(_vm.company, function (item) {
    return _c('v-card', {
      key: item.title,
      staticStyle: {
        "border-color": "rgba(255, 255, 255, 0.3) !important",
        "border-top-width": "0 !important"
      },
      attrs: {
        "data-aos": "fade-down",
        "data-aos-delay": item.aosDelay,
        "href": item.link,
        "target": "_blank",
        "outlined": "",
        "flat": ""
      }
    }, [_c('div', {
      staticClass: "pa-20px pa-md-30px pa-lg-40"
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.icon,
        "max-width": "36",
        "aspect-ratio": 1 / 1,
        "contain": ""
      }
    })], 1), _c('v-col', [_c('txt', {
      staticClass: "txt--xs white--text line-height-1 mb-4px mb-md-8px"
    }, [_vm._v(_vm._s(item.title))]), _c('txt', {
      staticClass: "font-secondary white--text font-weight-medium"
    }, [_vm._v(_vm._s(item.info))])], 1)], 1)], 1)]);
  })], 2)], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }