<template>
    <client-page>

        <template slot="subHead">
            <sub-visual sh="Customer Service" tabActive="온라인견적문의" bg="/images/sub/visual/sv-service.jpg" />
        </template>


        <page-section class="page-section--first">

            <tit-wrap-secondary class="pb-0">
                VIITZ의 공간 컨설팅 전문기업과 함께하세요.
                <template #TxtAdd>
                    오피스 환경 개선부터 병원, 교육기관, 호텔까지 다양한 공간에 대한 고민을 해결해 드립니다.
                </template>
            </tit-wrap-secondary>

        </page-section>       
            
        <page-section :style="$vuetify.breakpoint.mdAndUp ? 'background-image: url(/images/sub/service/inquiry/inquiry-bg.jpg)' : 'background-image: url(/images/sub/service/inquiry/inquiry-bg-mo.jpg)'">
            <tit-wrap data-aos="fade-up" class="tit-wrap--sm text-center text-md-left">
                <tit class="tit--sm white--text">견적/구매 문의 프로세스</tit>
            </tit-wrap>
            <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/service/inquiry/inquiry-img.svg" max-width="1200" :aspect-ratio="1200 / 240" contain class="w-100 d-none d-md-block" />            
            <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/service/inquiry/inquiry-img-mo.svg" max-width="240" :aspect-ratio="240 / 1200" contain class="w-100 mx-auto d-md-none" />
        </page-section>        
            
        <page-section class="pb-0">
            <tit-wrap class="tit-wrap--sm">
                <tit data-aos="fade-up" class="tit--sm">문의처</tit>
            </tit-wrap>
            <v-card tile flat color="grey lighten-5">
                <div class="pa-20px pa-md-30px px-lg-60px py-lg-40px">
                    <v-row class="mx-lg-n20px mx-xl-n50px">
                        <v-col cols="12" md="auto" class="px-lg-20px px-xl-50px">
                            <txt data-aos="fade-up" data-aos-delay="100" class="txt--xs line-height-1 mb-4px mb-md-8px">대표 전화번호</txt>
                            <txt data-aos="fade-up" data-aos-delay="150" class="txt--dark font-weight-medium"><a href="tel:1577-6536" target="_blank">1577-6536</a></txt>
                        </v-col>
                        <v-col cols="12" md="auto" class="px-lg-20px px-xl-50px">
                            <txt data-aos="fade-up" data-aos-delay="100" class="txt--xs line-height-1 mb-4px mb-md-8px">상담 가능 요일</txt>
                            <txt data-aos="fade-up" data-aos-delay="150" class="txt--dark font-weight-medium font-secondary">매주 월~금요일 09:00 ~ 17 :00</txt>
                        </v-col>
                        <v-col cols="12" md="auto" class="px-lg-20px px-xl-50px">
                            <txt data-aos="fade-up" data-aos-delay="100" class="txt--xs line-height-1 mb-4px mb-md-8px">점심시간</txt>
                            <txt data-aos="fade-up" data-aos-delay="150" class="txt--dark font-weight-medium font-secondary">11:30 ~ 13:00</txt>
                        </v-col>
                        <v-col cols="12" md="auto" class="px-lg-20px px-xl-50px">
                            <txt data-aos="fade-up" data-aos-delay="100" class="txt--xs line-height-1 mb-4px mb-md-8px">휴무</txt>
                            <txt data-aos="fade-up" data-aos-delay="150" class="txt--dark font-weight-medium font-secondary">토/일/공휴일 상담실 휴무</txt>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </page-section>    
            
        <page-section class="page-section--last">
            <tit-wrap class="tit-wrap--sm">
                <tit data-aos="fade-up" class="tit--sm">견적/구매 문의</tit>
            </tit-wrap>

            <form-input skin="purchase" code="estimate"/>
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import FormInput from "@/components/client/form/form-input.vue";

import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        FormInput,

        TitWrapSecondary,
        TitWrap,
        Tit,
        Txt,
    }
}
</script>

<style>

</style>