<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" class="icon-svg icon-point" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <g>
            <path d="M8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10ZM14 8.2C14 4.57 11.35 2 8 2C4.65 2 2 4.57 2 8.2C2 10.54 3.95 13.64 8 17.34C12.05 13.64 14 10.54 14 8.2ZM8 0C12.2 0 16 3.22 16 8.2C16 11.52 13.33 15.45 8 20C2.67 15.45 0 11.52 0 8.2C0 3.22 3.8 0 8 0Z"/>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-xx-small {
        height: 12px;
    }
    &-x-small {
        height: 14px;
    }
    &-small {
        height: 16px;
    }
    &-default {
        height: 20px;
    }
    &-large {
        height: 22px;
    }
    &-x-large {
        height: 24px;
    }
    &-xx-large {
        height: 26px;
    }
}
</style>
