var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--density-compact w-100 mw-md-120px",
    attrs: {
      "items": _vm.searchKeys,
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--density-compact w-100 mw-md-240px",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "min-w-80px min-w-md-100px",
    attrs: {
      "color": "grey darken-4",
      "tile": "",
      "dark": ""
    },
    on: {
      "click": _vm.search
    }
  }, [_vm._v("검색")])], 1)], 1), _c('table', {
    staticClass: "board-list font-secondary mt-12px mt-md-24px"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.fixed, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "18",
        "color": "primary"
      }
    }, [_vm._v("mdi-alert-circle-outline")])], 1), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(notification.subject))])])]), _c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" 관리자 ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.viewCount.format()) + " ")])]);
  }), _vm._l(_vm.notifications, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('span', [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))])]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(notification.subject))])])]), _c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" 관리자 ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.viewCount.format()) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('btn-primary', {
    attrs: {
      "to": "/service/inquiry"
    }
  }, [_vm._v("문의하기")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "8%"
    }
  }), _c('th', {
    staticStyle: {
      "width": "48%"
    }
  }, [_vm._v("게시물 제목이 옵니다. ")]), _c('th', {
    staticStyle: {
      "width": "17%"
    }
  }, [_vm._v("작성자")]), _c('th', {
    staticStyle: {
      "width": "17%"
    }
  }, [_vm._v("작성일")]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("조회")])])]);

}]

export { render, staticRenderFns }