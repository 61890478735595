<template>
    <client-page>

        <template slot="subHead">
            <sub-visual sh="Reference" bg="/images/sub/visual/sv-reference.jpg" />
        </template>

        <reference-category @category="setCategory"/>

        <page-section class="page-section--first page-section--last grey lighten-5">
            <reference-list :boards="boards"/>
            <div class="v-pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="init"/>
            </div>
        </page-section>
        
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import ReferenceCategory from "@/components/client/reference/reference-category.vue";
import ReferenceList from "@/components/client/reference/reference-list.vue";

import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        ReferenceCategory,
        ReferenceList,

        BtnArrowPrimary,
        Tit,
        Txt,
    },
    data() {
        return {
            filter: {
                code: "reference",
                category: null
            },
            pageCount: 0,
            limit: 12,
            page: 1,

            boards: [],
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { summary, boards } = await api.v1.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
            })
            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        async setCategory(category) {
            this.page = 1
            this.filter.category = category;
            await this.init();
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
