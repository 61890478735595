<template>
    <v-row class="my-n10px my-md-n20px">
        <!-- S: reference-item -->
        <v-col v-for="board in boards" :key="board._id" cols="6" md="4" class="py-10px py-md-20px">
            <v-card :to="`/product_view/${board._id}`" flat tile class="v-card--none-active-bg thumb-btn-wrap">
                <div class="thumb-btn">
                    <v-card flat outlined tile>
                        <v-img :src="board.thumb" max-width="384" :aspect-ratio="384 / 256" class="w-100"/>
                    </v-card>
                    <btn-arrow-secondary class="primary v-btn--none-active-bg" />
                </div>
                <div class="pt-16px pt-md-24px">
                    <!-- 2depth title    -->
                    <txt class="primary--text line-height-1 txt--xs mb-4px mb-md-8px">{{ board.category2 }}</txt>
                    <!-- 3depth title    -->
                    <txt class="txt--dark font-weight-medium">{{ board.category3 }}</txt>
                </div>
            </v-card>
        </v-col>
        <!-- E: reference-item -->
    </v-row>
</template>

<script>
import BtnArrowSecondary from "@/components/publish/parents/buttons/btn-arrow-secondary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {
        boards: { type: Array, default: () => [] }
    },
    components: {
        BtnArrowSecondary,
        Tit,
        Txt,
    },
}
</script>

<style lang="scss" scoped>

</style>
