<template>
    <client-page>

        <template slot="subHead">
            <sub-visual sh="Customer Service" tabActive="A/S" bg="/images/sub/visual/sv-service.jpg" />
        </template>     
            
        <page-section class="page-section--first pb-0">
            <tit-wrap class="tit-wrap--sm">
                <tit data-aos="fade-up" class="tit--sm">A/S 규정</tit>
            </tit-wrap>
            <v-row class="row--lg mx-lg-n40px">
                <v-col v-for="item in rule" :key="item.title" cols="12" md="6" class="px-lg-40px">
                    <div class="grey lighten-5 pa-10px text-center">
                        <txt data-aos="zoom-out" class="txt--dark font-weight-medium">
                            {{item.title}}
                        </txt>
                    </div>
                    <div v-for="child in item.children" :key="child.title">
                        <v-row class="row--sm py-12px py-md-24px">
                            <v-col data-aos="fade-right" data-aos-delay="100" cols="12" md="auto">
                                <div class="min-w-120px">
                                    <txt class="txt--sm txt--dark font-weight-medium">{{child.title}}</txt>
                                </div>
                            </v-col>
                            <v-col data-aos="fade-left" data-aos-delay="100">
                                <txt class="txt--sm">{{child.info}}</txt>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </div>
                </v-col>
            </v-row>

        </page-section>      
            
        <page-section>
            <tit-wrap class="tit-wrap--sm">
                <tit data-aos="fade-up" class="tit--sm">유상 서비스 기준</tit>
            </tit-wrap>
            <v-row no-gutters>
                <v-col v-for="(item, index) in info" :key="item.title" cols="6" md="3" class="info-card-wrap">
                    <u-card-icon outlined :index="index" :title="item.title" :icon="item.icon" class="h-100"/>
                </v-col>
            </v-row>
        </page-section> 
            
        <page-section class="grey lighten-5">
            <tit-wrap class="tit-wrap--sm text-center text-md-left">
                <tit data-aos="fade-up" class="tit--sm">A/S 프로세스</tit>
            </tit-wrap>
            <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/service/as/as-img.svg" max-width="1200" :aspect-ratio="1200 / 332" contain class="w-100 d-none d-md-block" />
            <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/service/as/as-img-mo.svg" max-width="312" :aspect-ratio="312 / 1180" contain class="w-100 mx-auto d-md-none" />
        </page-section>  
            
        <page-section class="page-section--last">
            <tit-wrap class="tit-wrap--sm">
                <tit data-aos="fade-up" class="tit--sm">A/S 요청</tit>
            </tit-wrap>

            <form-input skin="as" code="as" />
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import FormInput from "@/components/client/form/form-input.vue";

import UCardIcon from "@/components/publish/styles/cards/u-card-icon.vue";
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        FormInput,

        UCardIcon,
        TitWrap,
        Tit,
        Txt,
    },
    data() {
        return {
            rule : [
                {
                    title : "무상지원",
                    children : [
                        {
                            title : "품질 보증 기간",
                            info : "구매일 기준 1년 이내 [단, 본사 귀책 (제품 하자 등)이 아닌 고객 부주의로 인한 과실의 경우는 유상 진행]",
                        },
                        {
                            title : "비용 부담",
                            info : "부품비 + 시공/물류비 무상 (본사 부담)",
                        },
                    ]

                },
                {
                    title : "유상 지원",
                    children : [
                        {
                            title : "품질 보증 기간",
                            info : "구매일 기준 1년 이후",
                        },
                        {
                            title : "비용 부담",
                            info : "부품비 + 시공/물류비 유상 (고객 부담)",
                        },
                    ]

                },
            ],
            info : [
                { title : "품질 보증 기간 경과 ", icon : "/images/sub/service/as/as-icon1-1.svg", },
                { title : "소비자 과실로 인한 하자", icon : "/images/sub/service/as/as-icon1-2.svg", },
                { title : "천재지변으로 인한 결함", icon : "/images/sub/service/as/as-icon1-3.svg", },
                { title : "출고 제품의 변형(개조, 임의 변경)", icon : "/images/sub/service/as/as-icon1-4.svg", },
                { title : "부품의 수명이 다 한 경우", icon : "/images/sub/service/as/as-icon1-5.svg", },
                { title : "지정 서비스 담당자 이외 수리 시", icon : "/images/sub/service/as/as-icon1-6.svg", },
                { title : "설치 변경 시(제품 이동, 이사)", icon : "/images/sub/service/as/as-icon1-7.svg", },
                { title : "주의 사항 미준수로 인한 하자", icon : "/images/sub/service/as/as-icon1-8.svg", },
            ],
        };
    },
}
</script>

<style lang="scss" scoped>
.row{
    .info-card-wrap>.v-card.v-sheet--outlined{
        border-right-width: 0;
        border-top-width : 0;
    }
    .info-card-wrap:nth-child(2n)>.v-card.v-sheet--outlined{
        border-right-width: 1px;
    }
    .info-card-wrap:nth-child(1)>.v-card.v-sheet--outlined,    
    .info-card-wrap:nth-child(2)>.v-card.v-sheet--outlined{
        border-top-width: 1px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row{
        .info-card-wrap:nth-child(2n)>.v-card.v-sheet--outlined{
            border-right-width: 0;
        }
        .info-card-wrap:nth-child(4n)>.v-card.v-sheet--outlined{
            border-right-width: 1px;
        }
        .info-card-wrap:nth-child(3)>.v-card.v-sheet--outlined,
        .info-card-wrap:nth-child(4)>.v-card.v-sheet--outlined{
            border-top-width: 1px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>