var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "게시판",
      "tabActive": "FAQ",
      "bg": "/images/test.jpg"
    }
  }), _c('page-section', [_c('faq-list')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }