<template>
    <div>
        <v-container>
            <v-tabs hide-slider>
                <v-tab @click="setCatgory(null)">ALL</v-tab>
                <v-tab @click="setCatgory('INT')">INT+OFFICE</v-tab>
                <v-tab @click="setCatgory('OFFICE')">OFFICE</v-tab>
                <v-tab @click="setCatgory('EDUCATION')">PUBLIC·EDUCATION</v-tab>
                <v-tab @click="setCatgory('MEDICAL')">MEDICAL</v-tab>
                <v-tab @click="setCatgory('DORMITORY')">DORMITORY</v-tab>
            </v-tabs>
        </v-container>
    </div>
</template>

<script>
export default {
    methods: {
        setCatgory(category) {
            this.$emit("category", category)
        }
    }

}
</script>

<style lang="scss" scoped>
::v-deep{
    .container {
        max-width: var(--container);
        padding: 0;
    }
}
</style>