var render = function render(){
  var _vm$board, _vm$board2, _vm$board3, _vm$board4, _vm$board5, _vm$board6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', {
    attrs: {
      "eager": "",
      "fullscreen": "",
      "max-width": "100%",
      "content-class": "mw-100 h-100 grey darken-4",
      "width": "100%"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('div', {
    staticClass: "reference-view"
  }, [_c('div', {
    staticClass: "reference-view__slide"
  }, [_c('swiper', {
    ref: "swiperTop",
    staticClass: "swiper swiper--main",
    attrs: {
      "options": _vm.swiperOptionTop
    }
  }, _vm._l(_vm.board.images, function (image, index) {
    var _image$image;
    return _c('swiper-slide', {
      key: index
    }, [typeof image.image === 'object' ? _c('div', {
      staticClass: "swiper-slide__inner",
      style: `background-image: url(${image === null || image === void 0 ? void 0 : (_image$image = image.image) === null || _image$image === void 0 ? void 0 : _image$image.src})`
    }) : _vm._e()]);
  }), 1)], 1), _c('div', {
    staticClass: "reference-view__thumb-slide"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('swiper', {
    ref: "swiperThumbs",
    staticClass: "swiper swiper--thumb",
    attrs: {
      "options": _vm.swiperOptionThumbs
    }
  }, _vm._l(_vm.board.images, function (image, index) {
    var _image$image2;
    return _c('swiper-slide', {
      key: index
    }, [typeof image.image === 'object' ? _c('v-responsive', {
      staticClass: "swiper-slide__thumb w-100 image-card",
      style: `background-image: url(${image === null || image === void 0 ? void 0 : (_image$image2 = image.image) === null || _image$image2 === void 0 ? void 0 : _image$image2.src})`,
      attrs: {
        "aspect-ratio": 1920 / 980
      }
    }) : _vm._e()], 1);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "reference-view__contents pt-30px pt-md-60px"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-20px mb-md-30px mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('txt', {
    staticClass: "white--text line-height-15 mb-6px mb-md-12px"
  }, [_vm._v(_vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.ensubject))]), _c('tit', {
    staticClass: "white--text font-secondary mb-16px mb-md-24px"
  }, [_vm._v(_vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.subject))]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary', {
    staticClass: "swiper-btn-prev",
    attrs: {
      "color": "white"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary', {
    staticClass: "swiper-btn-next",
    attrs: {
      "color": "white",
      "direction": "right"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('v-row', {
    staticClass: "row--sm mx-lg-n20px",
    attrs: {
      "justify-lg": "end"
    }
  }, [_c('v-col', {
    staticClass: "px-lg-20px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs white--text"
  }, [_vm._v("업종")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs grey--text text--lighten-3"
  }, [_vm._v(_vm._s((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.sectors))])], 1)], 1)], 1), _c('v-col', {
    staticClass: "px-lg-20px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs white--text"
  }, [_vm._v("구성공간")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs grey--text text--lighten-3"
  }, [_vm._v(_vm._s((_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : _vm$board4.construction))])], 1)], 1)], 1), _c('v-col', {
    staticClass: "px-lg-20px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs white--text"
  }, [_vm._v("적용제품")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs grey--text text--lighten-3"
  }, [_vm._v(_vm._s((_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : _vm$board5.product))])], 1)], 1)], 1), _c('v-col', {
    staticClass: "px-lg-20px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs white--text"
  }, [_vm._v("시공일자")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt', {
    staticClass: "txt--xs grey--text text--lighten-3"
  }, [_vm._v(_vm._s((_vm$board6 = _vm.board) === null || _vm$board6 === void 0 ? void 0 : _vm$board6.constructedAt))])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]), _c('v-btn', {
    staticClass: "transparent v-size--xx-large",
    attrs: {
      "icon": "",
      "fixed": "",
      "right": "",
      "dark": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }