var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('template', {
    slot: "subHead"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "Customer Service",
      "tabActive": "E-카다로그",
      "bg": "/images/sub/visual/sv-service.jpg"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--first page-section--last grey lighten-5"
  }, [_c('tit-wrap-secondary', [_vm._v(" E-카다로그 다운로드 ")]), _c('v-img', {
    staticClass: "w-100 elevation-10 mx-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "100",
      "src": "/images/sub/service/catalog/catalog-img.png",
      "max-width": "400",
      "aspect-ratio": 400 / 566
    }
  }), _c('div', {
    staticClass: "btn-wrap",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-outlined-tile', {
    staticClass: "min-w-120px min-w-md-160px justify-space-between",
    attrs: {
      "href": "/file/catalog.pdf",
      "target": "_blank",
      "color": "primary"
    }
  }, [_vm._v(" PDF 보기 "), _c('u-icon', {
    staticClass: "v-icon--right"
  }, [_vm._v("add")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-outlined-tile', {
    staticClass: "min-w-120px min-w-md-160px justify-space-between",
    attrs: {
      "href": "/file/catalog.pdf",
      "download": "vol02_비츠_카탈로그.pdf",
      "color": "primary"
    }
  }, [_vm._v(" PDF 다운로드 "), _c('icon-arrow', {
    attrs: {
      "direction": "down"
    }
  })], 1)], 1)], 1)], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }